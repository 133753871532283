import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tools from '../../services/tools.js';
import Location from '../../assets/cards/location.png';
import '../components.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 'auto',
    width: 350,
  },
  paperMin: {
    marginBottom: 10
  },
}));

const handleDescription = (description) => {
  const size = description.length;
  if (size > 70) {
    description = description.slice(0, 70);
    description += '...';
  }
  return description;
}

 const CardContentForArtItem = (props) => {
   const classes = useStyles();

   return (
     props.displaySmartphoneVersion
     ?
     <div className='pointer' style={{marginTop: -50, marginBottom: 50}}>
       <Paper className={classes.paperMin} onClick={() => props.displayPage('artPresentation', props.id)}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <img src={props.img} alt={props.imgAlt} style={{width: "150px", height: "auto", borderRadius: "25px", padding: "20px"}}/>
          <div style={{display: "flex", flexDirection: "column", marginLeft: 10}}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <p className='cardTitle ralewayRegular' style={{color: props.titleColor, textAlign: "left"}}>{props.title}</p>
              <div style={{display: "flex", flexDirection: "row", marginTop: -10}}>
                <img src={Location} alt='location' style={{height: 15, marginRight: '0.5em'}}/>
                <p className='ralewayRegular itemSubtitle' style={{color: Tools.primaryPurple, marginTop: -2}}>{props.subtitle}</p>
              </div>
              <div>
                <p className='ralewayLight itemSubtitle' style={{color: "#525252", marginTop: -1, textAlign: "left"}}>{handleDescription(props.description)}</p>
              </div>
            </div>
          </div>
        </div>
       </Paper>
     </div>
     :
    <div>
      <div className='cardContent pointer' style={{marginBottom: '5em'}}>
        <Paper className={classes.paper} onClick={() => props.displayPage('artPresentation', props.id)}>
          <img src={props.img} alt={props.imgAlt} style={{width: 350, height: 235}}/>
          <div style={{display: 'flex', flexDirection: 'row', marginBottom: '1em'}}>
            <div style={{width: '75%', height: '5em', paddingLeft: '30px'}}>
              <p className='cardTitle fauneItalic' style={props.category === 'Fresques' ? {color: Tools.primaryPink, fontSize: '22px', display: 'flex', textAlign: 'left'} : {color: Tools.primaryGreen, fontSize: '22px', display: 'flex', textAlign: 'left'}}>{props.title}</p>
              <div style={{display: 'flex', flexDirection: 'row', marginTop: -40}}>
                <img src={Location} alt='location' style={{height: 20, marginTop: '1.6em', marginRight: '0.5em'}}/>
                <p className='fauneItalic' style={{color: Tools.primaryPurple, fontSize: '1.2em'}}>{props.subtitle}</p>
              </div>
            </div>
            <div style={{width: '25%', height: '5em', marginRight: '3%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={props.cardImg} alt={props.imgAlt} style={{width: '80%', height: '50%', paddingTop: '10px'}}/>
            </div>
          </div>
          <div className='textDotWrapper' style={{height: 'auto', paddingBottom: '30px'}}>
            <p className='ralewayLight' style={{color: '#525252', fontSize: '17px', lineHeight: '24px', paddingLeft: '30px', paddingRight: '30px', textAlign: 'left', overflow: 'hidden'}}>{props.description}</p>
          </div>
        </Paper>
      </div>
  </div>
  );
}
export default CardContentForArtItem;

CardContentForArtItem.propTypes = {
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  img: PropTypes.string,
  imgAlt: PropTypes.string,
  displayPage: PropTypes.func,
  titleColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cardImg: PropTypes.string,

};
