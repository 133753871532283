import React from 'react';
import PriceTable from '../../tables/vaePriceTableHandler.js';
import Tools from '../../../services/tools.js';
import IMG from '../../../assets/vae/01.png';
import '../../components.css';

 const PriceBloc = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
      <h2 style={{textAlign: 'left', marginLeft: '15%', marginRight: '15%', color: Tools.primaryPurple, fontSize: '1.5em'}}>Tarifs</h2>
      <div style={{marginLeft: '15%', marginRight: '15%'}}>
        <PriceTable {...props}/>
      </div>
      <img style={{width: '70%', height: 'auto', marginTop: 20, borderRadius: '5px'}} src={IMG} alt=''/>
    </div>
    :
    <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '19em'}}>
      <h2 style={{textAlign: 'left', marginLeft: '15%', marginRight: '15%', color: Tools.primaryPurple, fontSize: '2em'}}>Tarifs</h2>
      <div style={{marginLeft: '15%', marginRight: '15%', width: '70%', height: 'auto', flexDirection: 'row', display: 'flex'}}>
        <div style={{width: '70%', marginRight: '3%', height: '10em'}}>
        <PriceTable {...props}/>
        </div>
        <div style={{borderRadius: '5px', width: '37%', height: '10em'}}>
          <img style={{width: '100%', height: 315, borderRadius: '5px'}} src={IMG} alt=''/>
        </div>
      </div>
    </div>
  );
}
export default PriceBloc;
