import React from 'react';
import PropTypes from 'prop-types';
import '../components.css';

const CycleNetworkMenuRow = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
     {props.menuItem.map((row, index) => (
       <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: 30}}>
          <img src={row.icon} alt="" style={{width: 'auto', height: 'auto', marginLeft: "auto", marginRight: "auto"}}/>
        </div>
         <span style={{marginLeft: '1em'}}>{row.txt}</span>
       </div>
     ))}
    </div>
  );
}

export default CycleNetworkMenuRow;

CycleNetworkMenuRow.propTypes = {
  menuItem: PropTypes.array,
};
