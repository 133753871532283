import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IMG1 from '../../assets/home/1.png';
import IMG2 from '../../assets/home/2.png';
import IMG3 from '../../assets/home/3.png';
import IMG4 from '../../assets/home/4.png';
import Tools from '../../services/tools.js';
import Grid from '@material-ui/core/Grid';
import Slider from 'infinite-react-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  rootSmall: {
    flexGrow: 1,
    maxWidth: '107em',
  },
  paper: {
    height: 400,
    width: 300,
  },
}));

export default function CardList(props) {
  const classes = useStyles();

  return (
    props.displaySmartphoneVersion
    ?
    <div style={{position: 'relative'}}>
      <Slider arrows={true} prevArrow={<ArrowBackIosIcon style={{color: 'grey', paddingLeft: 10}}/>} nextArrow={<ArrowForwardIosIcon style={{color: 'grey', paddingRight: 10,}}/>}>
        {props.lastCourses.map((value, index) => (
          <div onClick={() => props.displayPage('coursePresentation', props.lastCourses[index].id)}>
            <img className='pointer' src={props.lastCourses[index].image1} alt='' style={{width: '50%', height: 'auto%'}}/>
            <p style={{display: 'flex', color: Tools.primaryPurple, fontWeight: 'bold', justifyContent: 'center', textAlign: 'center'}}>{props.lastCourses[index].name}</p>
          </div>
        ))}
      </Slider>
    </div>
    :
    <Grid style={{marginLeft: "15%", marginRight: "15%"}}>
      <Grid>
        <Grid style={{display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: 'wrap'}}>
          {props.lastCourses.map((value, index) => (
            <Grid key={value} item>
              {index === 0 ? <h2 id='courseTitle' style={{color: Tools.primaryPurple}}>Parcours à la une</h2> : <h2 className='courseTitleEmpty'>.</h2>}
              <div style={{width: 300, paddingLeft: "10px", paddingRight: "10px"}}>
                <div className={classes.paper}>
                  <img className='pointer' src={props.lastCourses[index].image1} alt='' style={{width: '100%', height: '100%', borderRadius: '10px'}} onClick={() => props.displayPage('coursePresentation', props.lastCourses[index].id)}/>
                </div>
                {
                  <p style={{display: 'flex', color: Tools.primaryPurple, fontWeight: 'bold', fontFamily: 'fauneBold', textAlign: 'left'}}>{props.lastCourses[index].name}</p>
                }
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
