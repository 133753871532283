import React, { useState, useRef } from "react";
import Button from '../buttons/iconButton.js';
import IconButton from '../../assets/contact/upload.svg';

const EmailFileExplorer = (props) => {
  const [image, setImage] = useState("");
  const inputFile = useRef(null);

  const handleFileUpload = e => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;
      //console.log("name : " + filename);
      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      //console.log("fileType : ", fileType);
      if (checkForFileExtension(fileType)) {
        setImage(files[0]);
      }
      else {
        alert('Merci de fournir un fichier a format jpg, png ou pdf uniquement');
      }
    }
  };

  const checkForFileExtension = (ext) => {
    ext = ext.toLowerCase();
    if (ext === 'jpg' || ext === 'png' || ext === 'pdf') {
      return true;
    }
    else {
      return false;
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div>
      <input
        style={{display: "none" }}
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
      />
      <img className="pointer" src={IconButton} alt=''onClick={() => onButtonClick()}/>
    </div>
  );
};

export default EmailFileExplorer;
