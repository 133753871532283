import React from 'react';
import PageTitle from '../components/titles/screenTitle.js';
import AppBar from '../components/appBar/appBar.js';
import Footer from '../components/footer/footer.js';
import Rando from '../assets/about/about.png';
import RandoMin from '../assets/about/aboutMin.png';
import PartnersBlock from '../components/blocks/about/partnersBlock.js';
import ButtonBlock from '../components/blocks/about/bottomButtonBlock.js';
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import { withRouter} from "react-router-dom";
import './screens.css';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0,
      screenHeight: 0,
      isNewsletterLoaderVisible: false,
      displaySmartphoneVersion: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
  }

  //used to display new page on screen
  _displayPage = (page, id) => {
    const url = getScreenComponent(page, id);
    this.props.history.push(url);
  }

  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  _handleLoaderState = () => {
    const currentState = this.state.isNewsletterLoaderVisible;
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  render() {
    return(
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <PageTitle
            displaySmartphoneVersion={this.state.displaySmartphoneVersion}
            title='À propos'
            subtitle="Les œuvres Art & Environnement comme les fresques murales qui composent les Itinéraires Artistiques sont autant d’occasions de se promener : à pied, à vélo (avec ou sans assistance électrique) ou en voiture. Vivez une expérience immersive singulière et découvrez des créations originales réalisées par des artistes de notoriété régionale, nationale et internationale."
            thirdTitle=" Au détour de ces œuvres, prenez le temps de visiter une exposition au Centre Photographique de Lectoure, à la  Maison des Ecritures de Lombez, à la Maison de l’Illustration de Sarrant ou bien faites une pause dans un des nombreux cafés culturels du Pays Portes de Gascogne."
          />

          {
            this.state.displaySmartphoneVersion
            ?
            <img src={RandoMin} alt='Bike' style={{width: '70%', height: 'auto', marginLeft: 'auto', marginRight: 'auto', marginTop: '4%', marginBottom: '2%'}}/>
            :
            <img src={Rando} alt='Bike' style={{width: '70%', height: 'auto', marginLeft: 'auto', marginRight: 'auto', marginTop: '4%', marginBottom: '2%'}}/>
          }
          <div style={this.state.displaySmartphoneVersion ? {marginLeft: '15%', marginRight: '15%', textAlign: 'left'} : {width: '35%', marginLeft: '15%', textAlign: 'left'}}>
            <div style={{lineHeight: '25px'}}>
              <p className='ralewayLight'>Le projet Itinéraires Artistiques en Pays Portes de Gascogne a été initié par le <span className='underlinePink'>PETR Pays Portes de Gascogne</span> et l’association <span className='underlinePink'>Culture Portes de Gascogne</span>, ces deux structures travaillant au développement culturel des 5 communautés de communes de l’est du Gers : Bastides de Lomagne, Coteaux Arrats Gimone, Gascogne Toulousaine, Lomagne Gersoise, Savès.</p>
              <p className='ralewayLight'>Chaque projet possède un plan de financement propre avec une part d’autofinancement de la commune accueillant l’œuvre mais les partenaires institutionnels habituels sont l’Europe via le programme LEADER+ du Pays Portes de Gascogne, la DRAC Occitanie et la Région Occitanie.</p>
            </div>
          </div>
          <PartnersBlock screenWidth={this.state.screenWidth} displaySmartphoneVersion={this.state.displaySmartphoneVersion} />
          <ButtonBlock displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion} />
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (About);
