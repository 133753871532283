import React from 'react';
import PropTypes from 'prop-types';
import InstagramIcon from '../../assets/home/insta.svg';
import Facebook from '../../assets/home/facebook.svg';
import '../components.css';

const FollowusItem = (props) => {
  return (
    <div className='footerItem' style={props.screenWidth > 737 ? {width: '20%', height: "15em"} : {width: '20%'}}>
      {/*followus title row*/}
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {/*followus title*/}
        <h3 className='colorWhite' style={{fontFamily: 'fauneBold'}}>{props.title}</h3>
      </div>
      {/*followus textField row*/}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 9}}>
          <img src={Facebook} alt='facebook' className='pointer' style={{width: "40px", height: "40px", paddingRight: "5px"}}/>
        <img src={InstagramIcon} alt='insta' className='pointer' style={{width: "40px", height: "40px", paddingLeft: "5px"}}/>
      </div>
    </div>
  );
}

export default FollowusItem;

FollowusItem.propTypes = {
  title: PropTypes.string,
};
