import React from 'react';
//import Button from '../../buttons/simpleButton.js';
import ButtonWidth from '../../buttons/buttonWidth.js';
import Tools from '../../../services/tools.js';
import Button from '@material-ui/core/Button';

import '../../components.css';

 const BottomButtonBlock = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', width: '70%', marginBottom: 30}}>
      <ButtonWidth width='auto' buttonName='VOIR LES FRESQUES' onClick={() => props.displayPage('muralPainting', 0)} buttonColor={Tools.primaryGreen}/>
      <ButtonWidth buttonName='VOIR LES OEUVRES ART & ENVIRONNEMENT' onClick={() => props.displayPage('art', 0)} buttonColor={Tools.primaryGreen}/>
      <ButtonWidth buttonName='VOIR LES PARCOURS' onClick={() => props.displayPage('culturalCourses', 0)} buttonColor={Tools.primaryGreen} />
    </div>
    :
    <div style={{width: '70%', height: '7em', marginBottom: '3%', marginLeft: 'auto', marginRight: 'auto'}}>
      <Button
        variant="contained"
        onClick={() => props.displayPage('muralPainting', 0)}
        style={{backgroundColor: Tools.primaryGreen, color: 'white', margin: 10, height: 40, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32}}
      >
        VOIR LES FRESQUES
      </Button>
      <Button
        variant="contained"
        onClick={() => props.displayPage('art', 0)}
        style={{backgroundColor: Tools.primaryGreen, color: 'white', margin: 20, height: 40, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32}}
      >
      VOIR LES OEUVRES ART & ENVIRONNEMENT
      </Button>
      <Button
        variant="contained"
        onClick={() => props.displayPage('culturalCourses', 0)}
        style={{backgroundColor: Tools.primaryGreen, color: 'white', margin: 10, height: 40, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32}}
      >
        VOIR LES PARCOURS
      </Button>
    </div>
  );
}
export default BottomButtonBlock;
