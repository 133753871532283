import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../buttons/buttonWidth.js';
import Tools from '../../services/tools.js';
import IMG1 from '../../assets/home/art.png';
import IMG2 from '../../assets/home/fresques.png';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  img: {
    marginBottom: "5%",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
}));

export default function CenteredGrid(props) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <div style={{marginBottom: 60, display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <img src={IMG2} alt='img2' className={classes.img} style={props.screenWidth > 737 ? {width: "auto"} : {width: '350px'}}/>
          <div>
            <Button
              width="300px"
              buttonName='Art & Environnement'
              buttonColor={Tools.primaryGreen}
              onClick={() => props.displayPage('art', 0)}
            />
          </div>
        </div>
        <div style={{marginBottom: 60, display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <img src={IMG1} alt='img1' className={classes.img} style={props.screenWidth > 737 ? {width: "auto"} : {width: '350px'}}/>
          <div>
            <Button
              width="300px"
              buttonName='Fresques'
              buttonColor={Tools.primaryPink}
              onClick={() => props.displayPage('muralPainting', 0)}
            />
          </div>
        </div>
      </div>
  );
}

CenteredGrid.propTypes = {
  displayPage: PropTypes.func
};
