import React from 'react';
import Tools from '../../services/tools.js';
import '../components.css';

 const VaePiceTable = (props) => {
  const margin = '0.2em';
  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>

      {/*table title line*/}
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: '40%', height: '6em', backgroundColor: Tools.primaryPurple, borderTopLeftRadius: '10px 10px', position: 'relative'}}>
          <p className='priceTableTitle fauneBold' style={{marginLeft: 10, marginTop: -1, position: 'absolute', top: '10%'}}>Durée de location</p>
        </div>
        <div style={{width: '30%', height: '6em', backgroundColor: Tools.primaryPurple, marginLeft: margin, marginRight: margin, position: 'relative'}}>
          <p className='priceTableTitle fauneBold' style={{marginLeft: 10, marginTop: -1, position: 'absolute', top: '10%'}}>Prix juillet-août</p>
        </div>
        <div style={{width: '30%', height: '6em', backgroundColor: Tools.primaryPurple, borderTopRightRadius: '10px 10px', position: 'relative'}}>
          <p className='priceTableTitle fauneBold' style={{marginLeft: 10, marginTop: -1, position: 'absolute', top: '10%'}}>Prix hors-saison</p>
        </div>
      </div>

      {/*table line 1*/}
      <div style={{display: 'flex', flexDirection: 'row', marginBottom: margin}}>
        <div style={{width: '40%', height: '4em', backgroundColor: Tools.primaryGrey}}>
          <p style={{marginLeft: 10}} className='priceTableSubtitle ralewayRegular'>{props.prices[0].rentDuration}</p>
        </div>
        <div style={{width: '30%', height: '4em', backgroundColor: Tools.primaryGrey, marginLeft: margin, marginRight: margin}}>
          <p className=' ralewaySemibold'>{props.prices[0].priceSeason + '€'}</p>
        </div>
        <div style={{width: '30%', height: '4em', backgroundColor: Tools.primaryGrey}}>
          <p className=' ralewaySemibold'>{props.prices[0].priceOffSeason + '€'}</p>
        </div>
      </div>

      {/*table line 2*/}
      <div style={{display: 'flex', flexDirection: 'row', marginBottom: margin}}>
        <div style={{width: '40%', height: '4em', backgroundColor: 'lightgrey'}}>
          <p style={{marginLeft: 10}} className='priceTableSubtitle ralewayRegular'>{props.prices[1].rentDuration}</p>
        </div>
        <div style={{width: '30%', height: '4em', backgroundColor: 'lightgrey', marginLeft: margin, marginRight: margin}}>
          <p className=' ralewaySemibold'>{props.prices[1].priceSeason + '€'}</p>
        </div>
        <div style={{width: '30%', height: '4em', backgroundColor: 'lightgrey'}}>
          <p className=' ralewaySemibold'>{props.prices[1].priceOffSeason + '€'}</p>
        </div>
      </div>

      {/*table line 3*/}
      <div style={{display: 'flex', flexDirection: 'row', marginBottom: margin}}>
        <div style={{width: '40%', height: '3em', backgroundColor: Tools.primaryGrey}}>
          <p style={{marginLeft: 10}} className='priceTableSubtitle ralewayRegular'>2 jours</p>
        </div>
        <div style={{width: '30%', height: '3em', backgroundColor: Tools.primaryGrey, marginLeft: margin, marginRight: margin}}>
          <p className=' ralewaySemibold'>{props.prices[2].priceSeason + '€'}</p>
        </div>
        <div style={{width: '30%', height: '3em', backgroundColor: Tools.primaryGrey}}>
          <p className=' ralewaySemibold'>{props.prices[2].priceOffSeason + '€'}</p>
        </div>
      </div>

      {/*table line 4*/}
      <div style={{display: 'flex', flexDirection: 'row', marginBottom: margin}}>
        <div style={{width: '40%', height: '3em', backgroundColor: 'lightgrey'}}>
          <p style={{marginLeft: 10}} className='priceTableSubtitle ralewayRegular'>{props.prices[3].rentDuration}</p>
        </div>
        <div style={{width: '30%', height: '3em', backgroundColor: 'lightgrey', marginLeft: margin, marginRight: margin}}>
          <p className=' ralewaySemibold'>{props.prices[3].priceSeason + '€'}</p>
        </div>
        <div style={{width: '30%', height: '3em', backgroundColor: 'lightgrey'}}>
          <p  className=' ralewaySemibold'>{props.prices[3].priceOffSeason + '€'}</p>
        </div>
      </div>

      {/*table line 5*/}
      <div style={{display: 'flex', flexDirection: 'row', marginBottom: margin}}>
        <div style={{width: '40%', height: '3em', backgroundColor: Tools.primaryGrey, borderBottomLeftRadius: '10px 10px'}}>
          <p style={{marginLeft: 10}} className='priceTableSubtitle ralewayRegular'>{props.prices[4].rentDuration}</p>
        </div>
        <div style={{width: '30%', height: '3em', backgroundColor: Tools.primaryGrey, marginLeft: margin, marginRight: margin}}>
          <p className=' ralewaySemibold'>{props.prices[4].priceSeason + '€'}</p>
        </div>
        <div style={{width: '30%', height: '3em', backgroundColor: Tools.primaryGrey, borderBottomRightRadius: '10px 10px'}}>
          <p className=' ralewaySemibold'>{props.prices[4].priceOffSeason + '€'}</p>
        </div>
      </div>
      <p style={{textAlign: 'left'}} className='ralewayLightitalic'>
        Remorques, sièges enfants et vélos suiveurs sont disponibles en option. Gilets-jaune, casque, porte-smartphones, pompe, clé, antivol et cartes d’itinéraires sont fournis gracieusement avec le vélo, ainsi qu’un exemplaire du Guide du Routard du Pays Portes de Gascogne, que l’utilisateur peut conserver après la location pour découvrir le territoire.
      </p>
    </div>
  );
}
export default VaePiceTable;

VaePiceTable.propTypes = {

};
