import React from 'react';
import PropTypes from 'prop-types';
import Title from './carouselTitle.js';
import Img from '../../assets/carousel/1.png';
import ImgMin from '../../assets/carousel/1Min.png';
import Dot from './carouselDot.js';

import '../components.css';

 const CarouselItem1 = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Title title='Itinéraires artistiques' subtitle1='Changez de cadre !' page='cycleNetwork' displayPage={props.displayPage} displaySmartphoneVersion={props.displaySmartphoneVersion}/>
      <img src={Img} alt='' style={{width: '100%', height: "auto", position: 'relative', minHeight: '25em'}}/>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: -30, zIndex: 5}}>
        <Dot slide={props.slide} slideChange={props.slideChange}/>
      </div>
    </div>
  );
}
export default CarouselItem1;

CarouselItem1.propTypes = {
  screenHeight: PropTypes.number,
  displayPage: PropTypes.func,
};
