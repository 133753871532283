export default function getScreenComponent(screen, id, props) {
  if (screen === 'home') {
    return '/accueil';
  }
  else if (screen === 'art') {
    return '/art';
  }
  else if (screen === 'culturalCourses') {
    return "/parcours_culturels";
  }
  else if (screen === 'muralPainting') {
    return "/fresques";
  }
  else if (screen === 'contact') {
    return "/contact";
  }
  else if (screen === 'about') {
    return "/a_propos";
  }
  else if (screen === 'cycleNetwork') {
    return "/reseau_cyclable";
  }
  else if (screen === 'vae') {
    return "/velo_electrique"
  }
  else if (screen === 'coursePresentation') {
    const url = "/presentation_parcours/" + id.toString();
    return url;
  }
  else if (screen === 'artPresentation') {
    const url = "/presentation_art/" + id.toString();
    return url;
  }
}
