import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import '../components.css';

 const SmalllButton = (props) => {
  return (
    <Button
      variant="contained"
      onClick={() => props.onClick()}
      style={{backgroundColor: props.buttonColor, color: 'white', width: 'auto', marginBottom: '1%', height: 40, fontWeight: 'bold'}}
    >
    {props.buttonName}
    </Button>
  );
}
export default SmalllButton;

SmalllButton.propTypes = {
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
  buttonColor: PropTypes.string
};
