 import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tools from '../../services/tools.js';
import Location from '../../assets/cards/location.png';
import '../components.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '350px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    lineHeignt: 20,
    marginBottom: "3em",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
  },
}));

 const CardContentMuralPainting = (props) => {
   const classes = useStyles();

   return (
    <div className='paper pointer' style={{marginBottom: 10, position: 'relative'}}>
    {
      props.displaySmartphoneVersion
      ?
      /*Smarphone version*/
      <Paper style={{minWidth: "300px"}} onClick={() => props.displayPage('artPresentation', props.id)}>
        <div style={{padding: "30px"}}>
          <img src={props.img} alt={props.imgAlt} style={{width: "100%"}}/>
          <span style={{display: 'flex', flexDirection: 'row'}}>
            <span style={{width: '75%', height: 'auto', marginTop: -20}}>
              <p className='cardTitle fauneBoldItalic' style={props.category == "Fresques" ? {color: Tools.primaryPink, fontSize: '1.5em'} : {color: Tools.primaryGreen, fontSize: '1.5em'}}>{props.title}</p>
              <span style={{display: 'flex', flexDirection: 'row', marginTop: -40}}>
                <img src={Location} alt='location' style={{height: 20, marginTop: '2em', marginRight: '0.5em'}}/>
                <p className='fauneItalic' style={{color: Tools.primaryPurple, fontSize: '1.1em', marginTop: "1.7em"}}>{props.subtitle}</p>
              </span>
            </span>
            <img src={props.cardImg} alt={props.imgAlt} style={{width: '10%', minWidth: 50, position: 'absolute', paddingRight: "30px",  right: 0, marginTop: "10px"}}/>
          </span>
        </div>
      </Paper>
      :
      /*Desktop version*/
      <Paper className={classes.paper} onClick={() => props.displayPage('artPresentation', props.id)}>
        <img src={props.img} alt={props.imgAlt} style={{width: 350, height: 235, borderTopLeftRadius: 5, borderTopRightRadius: 5}} />
        <div style={{display: 'flex', flexDirection: 'row', marginLeft: 18, marginRight: 18}}>
          <div style={{width: '75%', height: 'auto', marginLeft: '3%'}}>
            <p className='cardTitle fauneItalic' style={props.category === 'muralPainting'  ? {color: Tools.primaryPink, fontSize: '20px', lineHeight: '24px'} : {color: Tools.primaryGreen, fontSize: '20px', lineHeight: '24px'}}>{props.title}</p>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <img src={Location} alt='location' style={{height: 20, marginRight: '0.5em', marginTop: "auto", marginBottom: "auto"}}/>
              <span className='fauneItalic' style={{color: Tools.primaryPurple, fontSize: '1.2em'}}>{props.subtitle}</span>
            </div>
          </div>
          <div style={{width: '20%', height: 'auto', marginRight: '3%', marginTop: "1.5em"}}>
            <img src={props.cardImg} alt={props.imgAlt} style={{maxWidth: "60px", marginLeft: "11px"}}/>
          </div>
        </div>
        <div className='textDotWrapper' style={{height: 'auto'}}>
          <p className='ralewayLight' style={{color: props.descriptionColor, fontSize: '1em', color: '#858585', paddingLeft: "30px", paddingRight: "30px", fontSize: '14px', lineHeight: '20px', overflow: 'hidden'}}>{props.description}</p>
        </div>
      </Paper>
      }
    </div>
  );
}
export default CardContentMuralPainting;

CardContentMuralPainting.propTypes = {
  img: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cardImg: PropTypes.string,
  description: PropTypes.string
};
