import React from 'react';
import PageTitle from '../components/titles/screenTitle.js';
import AppBar from '../components/appBar/appBar.js';
import Footer from '../components/footer/footer.js';
import BottomBlock from '../components/blocks/culturalCourses/culturalCoursesBloc.js';
import CardList from '../components/cards/culturalCoursesCardList.js';
import Switch from '../components/switchs/switch.js';
import Map from '../components/map/interactiveMap.js';
import Container from '@material-ui/core/Container';
import * as Services from '../services/apiServices.js';
import $ from 'jquery';
import { withRouter} from "react-router-dom";
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import './screens.css';
import '../assets/mapplic/mapplic.css';
import Mapplic from '../assets/mapplic/mapplic.js';
import { jsPDF } from "jspdf";

class CulturalCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0,
      screenHeight: 0,
      switchState: 'carte',
      isNewsletterLoaderVisible: false,
      displaySmartphoneVersion: false,
      openLocation: "",
      courses: [],
      isLoading: false,
      isPageLoadedWithParams: false,
      rerenderMapKey: 1,
      selectedCourse: null,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
    window.addEventListener('load', this._loadMap);
    //checkif course is already selected in page URL
    const course = (new URLSearchParams(window.location.search)).get("location");
    if (course !== null) {
      this.setState({selectedCourse: course});
      this.setState({isPageLoadedWithParams: true});
    };
    this._loadMap();
    this.setState({isLoading: false});
  }

  componentWillUnmount() {
    window.removeEventListener('load', this._loadMap);
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
  }

  _loadMap = () => {
    console.log('map is loading')
    const tmp = $('#mapplic').mapplic({
      source: "interactiveMap.json",
      sidebar: false,
      minimap: false,
      smartip: true,
      fullscreen: false,
      zoom: true,
      zoombuttons: true,
      zoomoutclose: true,
      closezoomout: true,
      mousewheel: true,
      zoommargin: true,
      maxscale: 3,
    });
  console.log('map loaded')
  this.setState({map: tmp}, async () => {
    const courses = await Services.getAllCourses();
    this.setState({courses: courses});
    this.setState({displayMap: "block"});
    this.setState({displayList: "none"});


    // if course is selected, display course circuit
    try {
      if (this.state.selectedCourse !== null) {
        const course = document.getElementById(this.state.selectedCourse);
        course.style.display = "block";
        //hide title if location is opened (title in description like required)
        let titles = document.getElementsByClassName('mapplic-tooltip-title');
        for (let i = 0; i < titles.length; i++) {
          titles[i].style.color = "transparent";
          titles[i].style.height = "0px";
          titles[i].style.display = "block";
        }
      }
    }
    catch(e) {
      console.log(e);
    }

    // Location opened
    this.state.map.on('locationopened', function(e, location) {

      //hide title if location is opened (title in description like required)
      let titles = document.getElementsByClassName('mapplic-tooltip-title');
      for (let i = 0; i < titles.length; i++) {
        titles[i].style.color = "transparent";
        titles[i].style.height = "0px";
        titles[i].style.display = "block";
      }


      //get selected course
      const courseId = location.id;
      //display course circuit
      console.log(courseId);
      const course = document.getElementById(courseId);
      course.style.display = "block";
    });

    // Location closed
    this.state.map.on('locationclosed', function(e) {
      if ((new URLSearchParams(window.location.search)).get("location") !== null) {
        return;
      }
      let course = document.getElementById('Parcours_A');
      if (course !== "null")  {
        course.style.display = "none";
      }
      course = document.getElementById('Parcours_B');
      if (course !== "null")  {
        course.style.display = "none";
      }
      course = document.getElementById('Parcours_C');
      if (course !== "null") {
        course.style.display = "none";
      }
      course = document.getElementById('Parcours_D');
      if (course !== "null") {
        course.style.display = "none";
      }

      //display title if location is closed
      let titles = document.getElementsByClassName('mapplic-tooltip-title');
      for (let i = 0; i < titles.length; i++) {
        titles[i].style.color = "#70398a";
        titles[i].style.height = "auto";
        titles[i].style.display = "block";
      }


    });
    this.setState({selectedCourse: null});
  });
}

  _handleSwitchState = () => {
    if (this.state.switchState === 'liste') {
      this.setState({switchState: 'carte'});
      this.setState({displayMap: "block"});
      this.setState({displayList: "none"});
    }
    else {
      this.setState({switchState: 'liste'});
      this.setState({displayMap: "none"});
      this.setState({displayList: "block"});
    }
  }

  //used to display new page on screen
  _displayPage = (page, id) => {
    const url = getScreenComponent(page, id);
    this.props.history.push(url);
  }


  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  _handleLoaderState = () => {
    const currentState = this.state.isNewsletterLoaderVisible;
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  //function used to display course presentation page
  _handleCourseCardClicked = async (index, id) => {
    this._displayPage('coursePresentation', id);
    return;
  }

  _exportPdf = () => {
    const doc = new jsPDF();
    doc.text("Itinéraires Artistiques", 10, 10);
    doc.save("Itinéraires Artistiques.pdf");
  }

  render() {
    return(
      this.state.isLoading ? <div style={{display: "flex", justifyContent: "center", marginTop: "50vh", transform: "translateY(-50%)"}}class="lds-circle"><div></div></div> :
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <Container maxWidth="xl">
            <PageTitle displaySmartphoneVersion={this.state.displaySmartphoneVersion} title='Parcours culturels' subtitle='Ces 5 parcours culturels ont été conçus en partenariat avec le réseau d’Offices de Tourisme du territoire ainsi que le réseau de clubs de cyclotourisme afin de proposer des balades à vélo à la journée ou à la demi-journée, sur des routes calmes, agréables et présentant un intérêt touristique et culturel.'/>
            {/*switch block*/}
            <div style={this.state.displaySmartphoneVersion ? {width: '6em', height: '3em', marginLeft: '15%', marginRight: '15%', marginBottom: '1em'} : {width: '6em', height: '3em', marginLeft: '15%', marginRight: '15%', marginTop: '4em', marginBottom: '1em'}}>
              <Switch switchState={this.state.switchState} handleSwitchState={this._handleSwitchState}/>
            </div>
            {/*cards list or map*/}
            <div style={{display: this.state.displayList}}>
              <CardList displaySmartphoneVersion={this.state.displaySmartphoneVersion} handleCourseCardClicked={this._handleCourseCardClicked} courses={this.state.courses}/>
            </div>
            <div style={{display: this.state.displayMap}}>
              <Map exportPdf={this._exportPdf}/>
            </div>
            {/*Bottom button blocs*/}
            <BottomBlock displaySmartphoneVersion={this.state.displaySmartphoneVersion} displayPage={this._displayPage}/>
          </Container>
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (CulturalCourses);
