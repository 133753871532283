import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tools from '../../services/tools.js';
import '../components.css';

 const Description = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div id='' style={{marginTop: '1em', marginBottom: '1em'}}>
      <div style={{paddingLeft: "40px", paddingRight: "40px"}}>
        <div className='descrptionWrapper fauneBold'>
          <h2 className='fauneBold' style={{color: Tools.primaryPurple}}>Description</h2>
        </div>
        <div className='descrptionWrapper'>
          <p className='ralewayLight' style={{lineHeight: '25px'}}>{props.description}</p>
        </div>
        <div className='descrptionWrapper' style={{marginTop: -25}}>
          <p className='ralewayLight' style={{lineHeight: '25px'}}>{props.description2}</p>
        </div>
      </div>
    </div>
    :
    <div id='pictureBlocWrapper' style={{marginTop: '1em', marginBottom: '1em'}}>
      <div style={{width: '85%', height: 'auto', display: 'flex', flexDirection: 'column'}}>
        <div className='descrptionWrapper fauneBold'>
          <h2 className='fauneBold' style={{color: Tools.primaryPurple}}>Description</h2>
        </div>
        <div className='descrptionWrapper'>
          <p className='ralewayLight' style={{lineHeight: '25px'}}>{props.description}</p>
        </div>
        <div className='descrptionWrapper' style={{marginTop: -25}}>
          <p className='ralewayLight' style={{lineHeight: '25px'}}>{props.description2}</p>
        </div>
      </div>
      <div style={{width: 300}} />
        <div style={{display: 'flex', justifyContent: 'flex-end', width: '40%'}}>
          <Button
            variant="contained"
            onClick={() => props.exportPdf()}
            style={{backgroundColor: Tools.primaryGreen, color: 'white', width: "60%", minWidth: "200px", marginBottom: '1%', height: 40, fontWeight: 'bold'}}
          >
          EXPORTER LE TRAJET
          </Button>
        </div>
    </div>
  );
}
export default Description;

Description.propTypes = {
  description: PropTypes.string,
  description2: PropTypes.string
};
