import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../services/tools.js';
import Location1 from '../../assets/cards/location.png';
import Location2 from '../../assets/cards/location2.png';
import '../components.css';

const SimpleTitle = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    null
    :
    <div className='simpleTitleWrapper'>
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', paddingLeft: 20, width: 'auto', marginTop: -40}}>
        <h1 className='pageTitle ralewayLight' style={{color: Tools.primaryPurple, marginTop: '1.8em'}}>{props.title}</h1>
        <div style={{width: '100%', marginTop: '-3em', display: 'flex', flexDirection: 'row'}}>
          <img src={Location1} alt='subtitle 1' style={{height: '1.5em', marginTop: 'auto', marginBottom: 'auto', marginRight: '0.3em'}}/>
          <p className='fauneBoldItalic' style={{color: Tools.primaryPink, fontSize: '1.4em'}}>{props.subtitle1}</p>
        </div>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginTop: -25}}>
          <img src={Location2} alt='subtitle 1' style={{height: '1.5em', marginTop: 'auto', marginBottom: 'auto', marginRight: '0.3em'}}/>
          <p className='fauneBoldItalic' style={{color: Tools.primaryGreen, fontSize: '1.4em'}}>{props.subtitle2}</p>
        </div>
      </div>
    </div>
  );
}

export default SimpleTitle;

SimpleTitle.propTypes = {
  title: PropTypes.string,
  subtitle1: PropTypes.string,
  subtitle2: PropTypes.string,
};
