import React from 'react';
import Bike from '../../../assets/contact/contact.png';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '../../fileExplorer/emailFileExplorer.js';
import Button from '../../buttons/simpleButton.js';
import ButtonWidth from '../../buttons/buttonWidth.js';
import Tools from '../../../services/tools.js';
import '../../components.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Tools.secondaryGrey,
    borderColor: "#70398A",
    '& label': {
       color: '#525252',
    },
    '& label.Mui-focused': {
       color: 'black'
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Tools.secondaryPurple,
      borderWidth: '2px'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Tools.secondaryPurple,
      borderWidth: '3px'
    }
  }
}));

 const ContactBlock = (props) => {
  const classes = useStyles();
  return (
    props.displaySmartphoneVersion
    ?
    <div id='' style={{marginLeft: '15%', marginRight: '15%', display: 'flex', flexDirection: 'column'}}>
      <img src={Bike} alt='bike' width='100%'/>
      <TextField
        className={classes.root}
        label='Prénom et nom'
        type='search'
        variant='outlined'
        onChange={(e) => props.handleInputChange('fullName', e)}
        style={{width: '100%', marginTop: '1em', marginBottom: '1em'}}
      />
      <TextField
        className={classes.root}
        label='Email'
        type='search'
        variant='outlined'
        onChange={(e) => props.handleInputChange('email', e)}
        style={{width: '100%', marginTop: '1em', marginBottom: '1em'}}
      />
      <TextField
        className={classes.root}
        label='Message'
        type='search'
        variant='outlined'
        multiline
        rowsMax={15}
        onChange={(e) => props.handleInputChange('message', e)}
        rows={5}
        style={{width: '100%',  marginTop: '1em', marginBottom: '1em'}}
      />
      <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: '0.8em', marginBottom: '0.8em'}}>
        <IconButton buttonColor="#70398a"/>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span style={{paddingLeft: '0.8em', fontWeight: 'bold'}}>Ajoutez vos fichiers</span>
          <span style={{paddingLeft: '0.8em', fontSize: '0.8em', color: 'grey'}}>(fichiers jpg, png ou pdf)</span>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-start', marginBottom: 50}}>
        <ButtonWidth width='100%' buttonName='Envoyer' onClick={() => props.sendEmail()} buttonColor={Tools.primaryGreen} isLoading={props.isEmailLoaderVisible}/>
      </div>
    </div>
    :
    <div id='' style={{backgroundColor: 'transparent', height: 'auto', marginLeft: '15%', marginRight: '15%', display: 'flex', flexDirection: 'row', marginTop: '3em', marginBottom: '2em'}}>
      {/*left image*/}
      <div style={{backgroundColor: 'transparent', width: '50%', height: 'auto', textAlign: 'left', paddingRight: "40px"}}>
        <img src={Bike} alt='bike' style={{width: "100%"}}/>
      </div>
      {/*right textfield block*/}
      <div style={{width: '50%', marginLeft: -50}}>
        <TextField
          className={classes.root}
          label='Prénom et nom'
          type='search'
          variant='outlined'
          onChange={(e) => props.handleInputChange('fullName', e)}
          style={{width: '80%', marginTop: '1em', marginBottom: '1em'}}
        />
        <TextField
          className={classes.root}
          label='Email'
          type='search'
          variant='outlined'
          onChange={(e) => props.handleInputChange('email', e)}
          style={{width: '80%', marginTop: '1em', marginBottom: '1em'}}
        />
        <TextField
          className={classes.root}
          label='Message'
          type='search'
          variant='outlined'
          multiline
          rowsMax={15}
          onChange={(e) => props.handleInputChange('message', e)}
          rows={props.messageRow}
          style={{width: '80%',  marginTop: '1em', marginBottom: '1em'}}
        />
        <div style={{display: 'flex', justifyContent: 'flex-start', marginLeft: '10%', marginTop: '0.8em', marginBottom: '0.8em'}}>
          <IconButton buttonColor={Tools.primaryPurple}/>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <span style={{paddingLeft: '0.8em', fontWeight: 'bold'}}>Ajoutez vos fichiers</span>
            <span style={{paddingLeft: '0.8em', fontSize: '0.8em', color: 'grey'}}>(fichiers jpg, png ou pdf)</span>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-start', marginLeft: '10%', marginBottom: 50}}>
          <ButtonWidth width='220px' buttonName='Envoyer' onClick={() => props.sendEmail()} buttonColor="#2daf93" isLoading={props.isEmailLoaderVisible}/>
        </div>
      </div>
    </div>
  );
}
export default ContactBlock;

ContactBlock.propTypes = {
  handleInputChange: PropTypes.func,
  sendEmail: PropTypes.func,
  messageRow: PropTypes.number
};
