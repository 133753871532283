const K_WIDTH = 10;
const K_HEIGHT = 10;

const selectedMarkerStyle = {
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: '7px solid #e4247d',
  borderRadius: 50,
  backgroundColor: '#2daf93',
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 'bold',
  padding: 4
};

export {selectedMarkerStyle};
