import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Tools from '../../services/tools.js';

const options = [
  'ACCUEIL',
  'ART & ENVIRONNEMENT',
  'FRESQUE MURALES',
  'RÉSEAU CYCLABLE',
  'PARCOURS CULTURELS',
  'À PROPOS',
  'CONTACT',
];

const ITEM_HEIGHT = 100;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (props, option) => {
    switch (option) {
      case 'ACCUEIL':
        props.displayPage('home', 0);
        break;
      case 'ART & ENVIRONNEMENT':
        props.displayPage('art', 0);
        break;
      case 'FRESQUE MURALES':
        props.displayPage('muralPainting', 0);
        break;
      case 'RÉSEAU CYCLABLE':
        props.displayPage('cycleNetwork', 0);
        break;
      case 'PARCOURS CULTURELS':
        props.displayPage('culturalCourses', 0);
        break;
      case 'À PROPOS':
        props.displayPage('about', 0);
        break;
      case 'CONTACT':
        props.displayPage('contact', 0);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon style={{color: Tools.primaryOrange, fontSize: '1.3em'}}/>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 7,
            width: 'auto',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === 'ACCUEIL'} onClick={() => handleClose(props, option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
