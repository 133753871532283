import React from 'react';
import VaePiceTable from './vaePriceTable.js';
import VaePiceTableMin from './vaePriceTableMin.js';
import '../components.css';

 const VaePiceTableHandler = (props) => {
  return (
    props.displaySmartphoneVersion ? <VaePiceTableMin {...props}/> : <VaePiceTable {...props}/>
  );
}
export default VaePiceTableHandler;
