import React from 'react';
import PropTypes from 'prop-types';
import SimpleButton from '../../../components/buttons/buttonWidth.js';
import Tools from '../../../services/tools.js';
import '../../components.css';

 const BottomBlock = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div style={{marginBottom: '3%'}}>
      <p style={{marginBottom: '2em', color: 'dimgrey', marginLeft: '15%', marginRight: '15%', marginTop: 30}} className='ralewaySemibold'>Vous souhaitez avoir plus d’informations sur le dispositif, d’autres itinéraires ou réserver un vélo ? Cest par ici !</p>
      <span style={{marginRight: '1em'}}><SimpleButton buttonName='RÉSERVER' width='40%' onClick={() => props.displayPage('vae', 0)} buttonColor={Tools.primaryGreen}/></span>
      <SimpleButton buttonName='EN SAVOIR PLUS' onClick={() => window.location = "https://www.paysportesdegascogne.com/mobilite/le-velos-de-pays/"} buttonColor={Tools.primaryGreen} width='40%'/>
    </div>
    :
    <div style={{width: '31%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%', marginTop: '1em', marginBottom: '9em'}}>
      <p style={{marginBottom: '2em', color: 'dimgrey'}} className='bottomText ralewaySemibold'>Vous souhaitez avoir plus d’informations sur le dispositif, d’autres itinéraires ou réserver un vélo ? Cest par ici !</p>
      <span style={{marginRight: '3em'}}><SimpleButton buttonName='RÉSERVER' width='15em' onClick={() => props.displayPage('vae', 0)} buttonColor={Tools.primaryGreen}/></span>
      <SimpleButton buttonName='EN SAVOIR PLUS' onClick={() => window.location = "https://www.paysportesdegascogne.com/mobilite/le-velos-de-pays/"} buttonColor={Tools.primaryGreen} width='15em'/>
    </div>
  );
}
export default BottomBlock;

BottomBlock.propTypes = {
  displayPage: PropTypes.func,
};
