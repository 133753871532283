//const host = 'http://localhost:8000/api';
const host = 'https://ppdg-bo.slapp.me/api';

//function used to get all courses from api
export async function getAllCourses() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getAllCourses';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getAllCourses : ', error);
    return false;
  }
}

//function used to get last courses from api
export async function getLastCourses() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getLastCourses';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getLastCourses : ', error);
    return false;
  }
}

//function used to get specific course from api
export async function getCourse(courseId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = host + '/getCourse/' + courseId.toString();
  try {
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getCourse : ', error);
    return false;
  }
}

//function used to get all art related points of interest from api
export async function getAllArtPoi() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getAllArtPoi';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getAllArtPoi : ', error);
    return false;
  }
}

//function used to get all art related points of interest from api
export async function getLastArtPoi(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getLastArtPoi/' + id.toString();
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getLastArtPoi : ', error);
    return false;
  }
}

//function used to get all mural painting related points of interest from api
export async function getAllMuralPaintingPoi() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getAllMuralPaintingPoi';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getAllMuralPaintingPoi : ', error);
    return false;
  }
}

//function used to get all mural painting related points of interest from api
export async function getGpxBetweenPoints(pts) {
  console.log('PTS')
  console.log(pts)
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({pts: pts})
  };
  try {
    const url = host + '/getGpxBetweenPoints';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getGpxBetweenPoints : ', error);
    return false;
  }
}

//function used to get all art related points of interest from api
export async function getLastMuralPaintingPoi(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getLastMuralPaintingPoi/' + id.toString();
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getLastMuralPaintingPoi : ', error);
    return false;
  }
}

//function used to get a specific point of interest from api
export async function getPoiFromId(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getPoiFromId/' + id.toString();
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getPoiFromId : ', error);
    return false;
  }
}

//function used to get all vae prices
export async function getVaePrices() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/vae';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getVaePrices : ', error);
    return false;
  }
}

//function used to get all courses where poi is located
export async function getAllCoursesFromPoi(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getAllCoursesFromPoi/' + id.toString();
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getAllCoursesFromPoi : ', error);
    return false;
  }
}

//function used to get all courses where poi is located
export async function getCourseFromId(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getCourse/' + id.toString();
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getCourseFromId : ', error);
    return false;
  }
}

//function used to get all point of interest for map
export async function getAllPoiForMap(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getAllPoiForMap/';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getAllPoiForMap : ', error);
    return false;
  }
}

//function used to get all cycle CycleNetwork points
export async function getAllCycleNetworkPoi() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/getAllCycleNetworkPoi/';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function getAllCycleNetworkPoi : ', error);
    return false;
  }
}

//test function
export async function test() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const url = host + '/exportCourse';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function test : ', error);
    return false;
  }
}
