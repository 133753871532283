import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../../services/tools.js';
import ArtGrid from '../../grids/homeArtGrid.js';
import '../../components.css';

 const ArtBlock = (props) => {
  return (
    <div id='homeArtBlockDiv' style={{height: 'auto', paddingTop: 100, paddingBottom: 60}}>
      <h2 style={{color: Tools.primaryPurple, marginTop: -30, fontFamily: 'fauneBold'}}>Découvrez les œuvres</h2>
      <p className='ralewayLight' style={{minWidth: '20em', maxWidth: '40em', marginLeft: 30, marginRight: 30, fontSize: '1.1em', lineHeight: '24px'}}>
        Les œuvres Art & Environnement comme les fresques murales qui composent les Itinéraires Artistiques sont autant d’occasions de se promener : à pied, à vélo (avec ou sans assistance électrique) ou en voiture.
        Vivez une expérience immersive singulière et découvrez des créations originales réalisées par des artistes de notoriété régionale, nationale et internationale.
      </p>
      <div id='homeArtBlockImg'>
        <ArtGrid {...props}/>
      </div>
    </div>
  );
}
export default ArtBlock;

ArtBlock.propTypes = {
  props: PropTypes.object
};
