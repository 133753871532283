import React from 'react';
import PageTitle from '../components/titles/screenTitle.js';
import AppBar from '../components/appBar/appBar.js';
import BottomBlock from '../components/blocks/art/artBottomBloc.js';
import CardList from '../components/cards/artCardList';
import Footer from '../components/footer/footer.js';
import * as Services from '../services/apiServices.js';
import { withRouter} from "react-router-dom";
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import './screens.css';

class Art extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0,
      screenHeight: 0,
      isNewsletterLoaderVisible: false,
      displaySmartphoneVersion: false,
      poiList: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();

    const artPoiList = await Services.getAllArtPoi();
    console.log('artPoiList');
    console.log(artPoiList);
    this.setState({poiList: artPoiList});
    this.setState({isLoading: false});
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
  }

  //used to display new page on screen
  _displayPage = (page, id) => {
    const url = getScreenComponent(page, id);
    this.props.history.push(url);
  }
  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  _handleLoaderState = () => {
    const currentState = this.state.isNewsletterLoaderVisible;
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  render() {
    return(
      this.state.isLoading ? <div style={{display: "flex", justifyContent: "center", marginTop: "50vh", transform: "translateY(-50%)"}}class="lds-circle"><div></div></div> :
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <PageTitle displaySmartphoneVersion={this.state.displaySmartphoneVersion} title='Art & Environnement' subtitle='Héritières du mouvement Land Art, les œuvres qui jalonnent le parcours Art et Environnement résonnent avec une urgence d’agir. Avec la complicité des habitants du territoire les propositions conçues poussent à réfléchir sur l’état de notre planète.'/>
          <CardList category='art' displaySmartphoneVersion={this.state.displaySmartphoneVersion} displayPage={this._displayPage} poiList={this.state.poiList}/>
          <BottomBlock displayPage={this._displayPage}/>
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (Art);
