import React from 'react';
import PageTitle from '../components/titles/screenTitle.js';
import ContactBlock from '../components/blocks/contact/contactBlock.js';
import AppBar from '../components/appBar/appBar.js';
import Footer from '../components/footer/footer.js';
import { withRouter} from "react-router-dom";
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import './screens.css';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0,
      screenHeight: 0,
      messageRow: 0,
      fullName: '',
      email: '',
      message: '',
      isNewsletterLoaderVisible: false,
      isEmailLoaderVisible: false,
      displaySmartphoneVersion: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
  }

  //called when screen is resized
  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
    this._handleMessageRow();
  }

  //used to display new page on screen
  _displayPage = (page, id) => {
    const url = getScreenComponent(page, id);
    this.props.history.push(url);
  }
  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  //used to handle message row size for the responsive
  _handleMessageRow = () => {
    const width = window.innerWidth;
    if (window.innerWidth > 1800) {
      this.setState({messageRow: 11});
    }
    else if (width < 1800 && width >= 1700) {
      this.setState({messageRow: 8});
    }
    else if (width < 1700 && width >= 1600) {
      this.setState({messageRow: 7});
    }
    else if (width < 1600 && width >= 1500) {
      this.setState({messageRow: 6});
    }
    else if (width < 1500 && width >= 1400) {
      this.setState({messageRow: 4});
    }
    else if (width < 1400 && width >= 1300) {
      this.setState({messageRow: 3});
    }
    else if (width < 1300 && width >= 1200) {
      this.setState({messageRow: 2});
    }
    else {
      this.setState({messageRow: 1});
    }
  }

  _isEmailFormatOk = (email) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  _handleLoaderState(type) {
    let currentState;
    if (type === 'newsletter') {
      currentState = this.state.isNewsletterLoaderVisible;
    }
    else {
      currentState = this.state.isEmailLoaderVisible;
    }
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  _sendEmail = () => {
    if (this.state.email === '' || this.state.fullName === '' || this.state.message === '') {
      alert("Merci de renseigner l'ensemble des champs");
      return;
    }
    if (!this._isEmailFormatOk(this.state.email)) {
      alert('Merci de renseigner un email valide');
      return;
    }
    this.setState({isLoaderVisible: true});
  }

  render() {
    return(
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <PageTitle displaySmartphoneVersion={this.state.displaySmartphoneVersion} title='Contact' subtitle='Vous avez besoin d’un renseignement concernant une des œuvres ? Un parcours vélo ? Un retour d’expérience à nous faire ? Avez besoin de notre communication physique ? Vous souhaiteriez être partenaire de la démarche ? N’hésitez pas à nous écrire !  Nous recevons déjà beaucoup de mails mais nous vous répondrons dans les meilleurs délais !'/>
          <ContactBlock {...this.state} handleInputChange={this._handleInputChange} sendEmail={this._sendEmail} />
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (Contact);
