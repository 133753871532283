import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../../services/tools.js';
import ICON1 from '../../../assets/home/1.svg';
import ICON2 from '../../../assets/home/2.svg';
import ICON3 from '../../../assets/home/4.svg';
import '../../components.css';

 const ButtonBlock = (props) => {
  return (
    <div id='homeButtonBlockDiv' style={{paddingTop: 100, paddingBottom: 100, backgroundColor: Tools.primaryGrey, marginTop: -5, display: 'flex', justifyContent: "center"}}>
      <div style={{minHeight: "212px", paddingLeft: '20px'}}>
        {/*<img className='pointer homeImgButton' style={props.screenWidth > 737 ? {width: '150px'} : {width: '100px'}} alt='img2' src={ICON1} onClick={() => props.displayPage('culturalCourses', 0)}/>*/}
        <img className='pointer homeImgButton' style={props.screenWidth > 737 ? {width: '150px'} : {width: '100px'}} alt='img2' src={ICON1} onClick={() => props.test()}/>

        <p class='homeButtonText homeImgButton' style={{color: Tools.primaryPurple, marginTop: "20px"}}>Trouver</p>
        <p class='homeButtonText homeImgButton' style={{color: Tools.primaryPurple}}>un parcours</p>
      </div>
      <div style={{marginLeft: "10%", marginRight: "10%", minHeight: "200px"}}>
        <img className='pointer homeImgButton' style={props.screenWidth > 737 ? {width: '150px'} : {width: '100px'}} alt='img2' src={ICON2} onClick={() => props.displayPage('cycleNetwork', 0)}/>
        <p class='homeButtonText homeImgButton' style={{color: Tools.primaryPurple, marginTop: "20px"}}>Créer</p>
        <p class='homeButtonText homeImgButton' style={{color: Tools.primaryPurple}}>mon parcours</p>
      </div>
      <div style={{minHeight: "200px", paddingRight: "10px"}}>
        <img className='pointer homeImgButton' style={props.screenWidth > 737 ? {width: '150px'} : {width: '100px'}} alt='img2' src={ICON3} onClick={() => props.displayPage('vae', 0)}/>
        <p class='homeButtonText homeImgButton' style={{color: Tools.primaryPurple, marginTop: "20px"}}>Louer</p>
        <p class='homeButtonText homeImgButton' style={{color: Tools.primaryPurple}}>un vélo électrique</p>
      </div>
    </div>
  );
}
export default ButtonBlock;

ButtonBlock.propTypes = {
  displayPage: PropTypes.func
};
