const Tools = {
  primaryPurple: '#70398a',
  secondaryPurple: '#4d1767',
  primaryOrange: '#fbc00d',
  secondaryOrange: '#f9bf12',
  primaryGreen: '#2daf93',
  primaryPink: '#e4247d',
  primaryGrey: '#f5f5f5',
  secondaryGrey: '#eeeeee',
  thirdGrey: '#525252'
};
export default Tools;

//function used to validate user email
export function isEmailFormatOk(email) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}
