import React from 'react';
import Tools from '../../services/tools.js';
import VerticalStepper from '../steppers/verticalStepper.js';
import '../components.css';

 const Details = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
      <div style={{paddingLeft: "40px", paddingRight: "40px"}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className='descrptionWrapper'>
            <h2 className='fauneBold' style={{color: Tools.primaryPurple}}>Détails de la balade</h2>
          </div>
          <VerticalStepper {...props}/>
        </div>
      </div>
    :
    <div id='pictureBlocWrapper' style={{marginTop: '1em', marginBottom: '1em'}}>
      <div style={{width: '65%', display: 'flex', flexDirection: 'column'}}>
        <div className='descrptionWrapper'>
          <h2 className='fauneBold' style={{color: Tools.primaryPurple}}>Détails de la balade</h2>
        </div>
        <VerticalStepper {...props}/>
      </div>
    </div>
  );
}
export default Details;
