import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreHoriz';
import Button from '../buttons/buttonWidth.js';
import Tools from '../../services/tools.js';
import Rows from '../rows/cycleNetworkMenuRow.js';
import Icon1Svg from '../../assets/cycleNetwork/menuItem1.svg';
import Icon2Svg from '../../assets/cycleNetwork/menuItem2.svg';
import Icon3Svg from '../../assets/cycleNetwork/menuItem3.svg';
import Icon4Svg from '../../assets/cycleNetwork/menuItem4.svg';
import Icon5Svg from '../../assets/cycleNetwork/menuItem5.svg';
import Icon6Svg from '../../assets/cycleNetwork/menuItem6.svg';
import Icon7Svg from '../../assets/cycleNetwork/menuItem7.svg';
import Icon8Svg from '../../assets/cycleNetwork/menuItem8.svg';
import Icon9Svg from '../../assets/cycleNetwork/menuItem9.svg';
import Icon10Svg from '../../assets/cycleNetwork/menuItem10.svg';
import Icon11Svg from '../../assets/cycleNetwork/menuItem11.svg';
import Icon12Svg from '../../assets/cycleNetwork/menuItem12.svg';
import Icon13Svg from '../../assets/cycleNetwork/menuItem13.svg';
import Icon14Svg from '../../assets/cycleNetwork/menuItem14.svg';
import Icon15Svg from '../../assets/cycleNetwork/menuItem15.svg';
import Icon16Svg from '../../assets/cycleNetwork/menuItem16.svg';
import Icon17Svg from '../../assets/cycleNetwork/menuItem17.svg';
import Icon18Svg from '../../assets/cycleNetwork/menuItem18.svg';
import Icon19Svg from '../../assets/cycleNetwork/menuItem19.svg';
import Icon20Svg from '../../assets/cycleNetwork/menuItem20.svg';
import Icon21Svg from '../../assets/cycleNetwork/menuItem21.svg';

//import PropTypes from 'prop-types';

const culturalPoints = [
  {
    icon: Icon1Svg,
    txt: 'Fresques murales'
  },
  {
    icon: Icon2Svg,
    txt: 'Oeuvres Arts et Environnements'
  },
  {
    icon: Icon3Svg,
    txt: 'Offices de Tourisme'
  },
  {
    icon: Icon4Svg,
    txt: 'Médiathèques - Bibliothèques'
  },
  {
    icon: Icon5Svg,
    txt: 'Librairies'
  },
  {
    icon: Icon6Svg,
    txt: 'Cafés culturels',
  },
  {
    icon: Icon7Svg,
    txt: 'Lieux culturels',
  },
];

const technicalPoints = [
  {
    icon: Icon8Svg,
    txt: 'Aires de pique-nique'
  },
  {
    icon: Icon9Svg,
    txt: 'Bases de loisirs'
  },
  {
    icon: Icon10Svg,
    txt: 'Toilettes publiques'
  },
  {
    icon: Icon11Svg,
    txt: 'Points d’eau potable'
  },
  {
    icon: Icon12Svg,
    txt: 'Gares multimodales'
  },
  {
    icon: Icon13Svg,
    txt: 'Bornes de recharge VAE',
  },
  {
    icon: Icon14Svg,
    txt: 'Points de location Vélos de Pays',
  },
  {
    icon: Icon15Svg,
    txt: 'Réparateurs - Entretien vélo'
  },
  {
    icon: Icon16Svg,
    txt: 'Mairie'
  },
  {
    icon: Icon17Svg,
    txt: 'Points de vue'
  },
  {
    icon: Icon18Svg,
    txt: 'Forêts'
  },
  {
    icon: Icon19Svg,
    txt: 'Chemins',
  },
  {
    icon: Icon20Svg,
    txt: 'Jardins',
  },
  {
    icon: Icon21Svg,
    txt: 'Lacs',
  },
];

//Map menu
const MapMenu = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div>
      <div style={{width: '22em', height: 'auto', border: '2px solid purple', backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto'}}>
        <div style={{textAlign: 'left', paddingLeft: 20, marginTop: 30}}>
          <h1 style={{color: Tools.primaryPurple, fontSize: '1em'}}>Les points culturels</h1>
          <Rows menuItem={culturalPoints} />
          <h1 style={{color: Tools.primaryPurple, fontSize: '1em'}}>Les points techniques</h1>
          <Rows menuItem={technicalPoints} />
        </div>
      </div>
      <div style={{marginTop: 10, marginBottom: 10}}>
        <Button width={355} buttonColor={Tools.primaryOrange} buttonName='Exporter le trajet' onClick={props.exportButtonClicked}/>
      </div>
    </div>
    :
    <div>
      <div style={{width: '22em', height: 'auto', border: '1px solid black', marginLeft: -300, marginTop: -60, backgroundColor: 'white', boxShadow: '-5px 10px rgba(0, 0, 0, 0.5)', input: '-webkit-autofill'}}>
        {/*Open remove menu button*/}
        <div style={{width: '3em', height: '3em', backgroundColor: props.orange, position: 'absolute', top: 0, right: 0, borderRadius: '50%'}}>
          <MoreVertIcon className='pointer' onClick={() => props.handleMenuClick()} style={{color: 'white', display: 'tableCell', verticalAlign: 'middle', minHeight: '1.5em', fontSize: '2em'}} />
        </div>
        <div style={{textAlign: 'left', paddingLeft: 20, marginTop: 30}}>
          <h1 style={{color: Tools.primaryPurple, fontSize: '1em'}}>Les points culturels</h1>
          <Rows menuItem={culturalPoints} />
          <h1 style={{color: Tools.primaryPurple, fontSize: '1em'}}>Les points techniques</h1>
          <Rows menuItem={technicalPoints} />
        </div>
      </div>
      <div style={{marginLeft: -300, marginTop: 30}}>
        <Button width={355} buttonColor={Tools.primaryOrange} buttonName='Exporter le trajet' onClick={props.exportButtonClicked}/>
      </div>
    </div>
  );
}

export default MapMenu;

MapMenu.propTypes = {
};
