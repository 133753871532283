import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../services/tools.js';
import '../components.css';

const Screentitle = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '15%', marginRight: '15%'}}>
      <div style={{display: 'flex', textAlign: 'left', marginTop: 10}}>
        <h1 className='pageTitleMin' style={{color: Tools.primaryPurple}}>{props.title}</h1>
      </div>
      <div style={{display: 'flex', textAlign: 'left', marginTop: 10}}>
        <p className='ralewayLight' style={{lineHeight: "24px", fontSize: '1.1em', marginTop: -15, paddingBottom: 20, color: Tools.thirdGrey}}>{props.subtitle}</p>
      </div>
      <div style={{display: 'flex', textAlign: 'left', marginTop: 10}}>
        <p className='ralewayLight' style={{lineHeight: "24px", fontSize: '1.1em', marginTop: -15, paddingBottom: 20, color: Tools.thirdGrey}}>{props.thirdTitle}</p>
      </div>
    </div>
    :
    <div className='titleWrapper'>
      <div className='titleGreenBorder' style={{borderColor: Tools.primaryGreen}}>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', paddingLeft: 40, width: 'auto', marginTop: -40}}>
        <h1 className='pageTitle' style={{color: Tools.primaryPurple}}>{props.title}</h1>
        <div style={{width: "49%"}}>
          <p className='ralewayLight' style={{lineHeight: "24px", fontSize: '1.1em', marginTop: -15, paddingBottom: 10, color: Tools.thirdGrey}}>{props.subtitle}</p>
          <span className='ralewayLight' style={{lineHeight: "24px", fontSize: '1.1em', marginTop: -30, paddingBottom: 20, color: Tools.thirdGrey}}>{props.thirdTitle}</span>
          <p><span className='ralewayLight italic' style={{lineHeight: "24px", fontSize: '1.1em', paddingBottom: 20, color: Tools.thirdGrey}}>{props.lastTitle}</span></p>
        </div>
      </div>
    </div>
  );
}

export default Screentitle;

Screentitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  thirdTitle: PropTypes.string,
  lastTitle: PropTypes.string
};
