import React from 'react';
import AppBar from '../components/appBar/appBar.js';
import Footer from '../components/footer/footer.js';
import Title from '../components/titles/simpleTitleWithTimer.js';
import PictureBloc from '../components/blocks/pictureBloc.js';
import Description from '../components/blocks/description.js';
import BottomInformation from '../components/blocks/bottomInformation.js';
import Details from '../components/blocks/details.js';
import ArtList from '../components/cards/artCardListForCourse.js';
import Container from '@material-ui/core/Container';
import * as Services from '../services/apiServices.js';

import { withRouter} from "react-router-dom";
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import './screens.css';
import { jsPDF } from "jspdf";

class CoursesPresentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0,
      screenHeight: 0,
      isNewsletterLoaderVisible: false,
      displaySmartphoneVersion: false,
      touristOfficeLink: '',
      course: {},
      stages: [],
      poiList: [],
      information: "",
      isLoading: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
    await this._getDataFromApi(this.props.match.params.id);
    this.setState({isLoading: false});
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
  }

  //used to display new page on screen
  _displayPage = (page, id) => {
    const url = getScreenComponent(page, id);
    this.props.history.push(url);
  }

  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  _exportPdf = () => {
    const doc = new jsPDF();
    doc.text("Itinéraires Artistiques", 10, 10);
    doc.save("Itinéraires Artistiques.pdf");
  }

  _handleDisplayMap = () => {
    const name = this.state.course.name;
    if (name === "Lignes de Fuite - Perspectives entre Save et Gimone") {
      this.props.history.push('/parcours_culturels?location=Parcours_A');
      return;
    }
    else if (name === "À Propos - Des cycles et des lettres en Lomagne") {
      this.props.history.push('/parcours_culturels?location=Parcours_B');
      return;
    }
  }

  _handleLoaderState = () => {
    const currentState = this.state.isNewsletterLoaderVisible;
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  _openTouristOfficePage = () => {
    window.open(this.state.touristOfficeLink, '_blank');
  }

  async _getDataFromApi(id) {
    const course = await Services.getCourseFromId(id);
    console.log('course');
    console.log(course);
    this.setState({course: course});
    let poi;
    let url;
    let poiList = [];

    //parse all points of interest related to the course
    for (let i = 0; i < this.state.course.pointOfInterest.length; i++) {
      //get id of point of interest
      url = this.state.course.pointOfInterest[i];
      id = url.substring(url.lastIndexOf('/') + 1);
      //get point of interest from id
      poi = await Services.getPoiFromId(id);
      poiList.push(poi[0]);
      this.setState({poiList: poiList});
    }
    //set course stages
    this.setState({stages: this.state.course.stages.split('\n')});
    //set course information
    this.setState({information: this.state.course.information});
    //set course tourist office website link and stop loader
    this.setState({touristOfficeLink: this.state.course.touristOfficeLink}, function() {
      this.setState({isLoading: false});
    });
  }

  render() {
    return(
      this.state.isLoading ? <div style={{display: "flex", justifyContent: "center", marginTop: "50vh", transform: "translateY(-50%)"}}class="lds-circle"><div></div></div> :
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <Title displaySmartphoneVersion={this.state.displaySmartphoneVersion} title={this.state.course.name} courseTime={this.state.course.time} courseDist={this.state.course.distance}/>
          <PictureBloc title={this.state.course.name} difficulty={this.state.course.difficulty} courseTime={this.state.course.time} courseDist={this.state.course.distance} displaySmartphoneVersion={this.state.displaySmartphoneVersion} img1={this.state.course.image1} img2={this.state.course.image2} img3={this.state.course.image3}/>
          <Description exportPdf={this._exportPdf} displaySmartphoneVersion={this.state.displaySmartphoneVersion} description={this.state.course.description}/>
          <Details {...this.state}/>
          <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "1em"}}>
            <img className="pointer" onClick={() => this._handleDisplayMap()} src={this.state.course.imageMap} alt='course presentation img' style={this.state.displaySmartphoneVersion ? {width: "100%", borderRadius: "20px", paddingLeft: "40px", paddingRight: "40px"} : {width: "70%", borderRadius: '5px'}}/>
          </div>
          <ArtList displaySmartphoneVersion={this.state.displaySmartphoneVersion} displayPage={this._displayPage} poiList={this.state.poiList}/>
          <BottomInformation exportPdf={this._exportPdf} handleLinkClicked={this._displayPage} {...this.state} openTouristOfficePage={this._openTouristOfficePage}/>
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (CoursesPresentation);
