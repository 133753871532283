import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './screens/home.js';
import Art from './screens/artAndEnvironnement.js';
import About from './screens/about.js';
import CulturalCourses from './screens/culturalCourses.js';
import MuralPainting from './screens/muralPainting.js';
import Contact from './screens/contact.js';
import CycleNetwork from './screens/cycleNetwork.js';
import Vae from './screens/vae.js';
import CoursePresentation from './screens/coursePresentation.js';
import ArtPresentation from './screens/artPresentation.js';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact path="/presentation_art/:id" component={<ArtPresentation />}>
        <ArtPresentation />
      </Route>
      <Route exact path="/velo_electrique" component={<Vae />}>
        <Vae />
      </Route>
      <Route exact path="/presentation_parcours/:id" component={<CoursePresentation />}>
        <CoursePresentation />
      </Route>
      <Route exact path="/contact" component={<Contact />}>
        <Contact />
      </Route>
      <Route exact path="/reseau_cyclable" component={<CycleNetwork />}>
        <CycleNetwork />
      </Route>
      <Route path="/fresques" component={<MuralPainting />}>
        <MuralPainting />
      </Route>
      <Route exact path="/parcours_culturels" component={<CulturalCourses />}>
        <CulturalCourses />
      </Route>
      <Route exact path="/a_propos" component={<About />}>
        <About />
      </Route>
      <Route exact path="/art" component={<Art />}>
        <Art />
      </Route>
      <Route exact path="/accueil">
        <Home />
      </Route>
      <Route exact path="/">
        <Redirect to="/accueil" />
      </Route>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
