import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import Tools from '../../services/tools.js';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import '../../screens/loader.css';
import '../components.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 45,
    backgroundColor: 'white',
    '& label': {
       color: 'dimgray',
       fontStyle: 'italic',
       marginTop: '-5px'
    },
    '& label.Mui-focused': {
       color: 'dimgray',
       underline: 'transparent'
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-input' : {
      marginTop: '-5px'
    }
  }
}));

const CustomForm = ({props, onValidated}) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    console.log('submit')
    props.handleLoaderState();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email,
    });
  }

  return (
    <div className='footerItem' style={props.screenWidth > 737 ? {width: '20%', height: "15em"} : {width: '20%'}}>
      {/*newletter title row*/}
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {/*newletter title*/}
        <h3 className='colorWhite' style={{fontFamily: 'fauneBold'}}>{props.title}</h3>
      </div>
      {/*newletter textField row*/}
      <div style={{ display: 'flex', flexDirection: 'row', height: 45}}>
      {/*newletter textField*/}
        <TextField
          className={classes.root}
          label='entrez votre email'
          type='search'
          variant='filled'
          onChange={e => setEmail(e.target.value)}
          InputProps={{ disableUnderline: true }}
          style={{width: '100%', color: 'transparent', maxWidth: "11em", marginTop: 14, height: 45}}
        />
        {/*newletter textField button*/}
        <div className='pointer' style={{width: '3.4em', height: 45, backgroundColor: Tools.secondaryOrange, marginTop: 14}} onClick={() => handleSubmit({email})}>
          {props.isNewsletterLoaderVisible ? <div className='loader' style={{marginTop: "7px", marginLeft: "12px"}}/> : <CheckIcon style={{color: 'white', fontSize: '2em', marginTop: 5}}/>}
        </div>
      </div>
    </div>
  );
}

const NewsletterItem = (props) => {
  const [isAlreadyShown, setIsAlreadyShown] = useState(false);
  const url = 'https://slapp.us5.list-manage.com/subscribe/post?u=1e56bf7a7da0126dfc2df8c43&id=407690e9ec';

  const handleAlert = () => {
    setIsAlreadyShown(true);
    alert('Vous êtes maintenant inscrit à notre newletter');
    props.handleLoaderState();
  }

  const handleError = () => {
    setIsAlreadyShown(true);
    alert('Une erreur est survenue, merci de réésayer plus tard');
    props.handleLoaderState();
  }

  return (
    <div>
    <MailchimpSubscribe
      url={url}
      render={({subscribe, status}) => (
        <div>
          <CustomForm props={props} onValidated={formData => subscribe(formData)} />
          {status === 'success' && !isAlreadyShown ? handleAlert() : null}
          {status === 'error' && !isAlreadyShown ? handleError() : null}
        </div>
    )}
  />
    </div>
  );
}
export default NewsletterItem;

NewsletterItem.propTypes = {
  title: PropTypes.string,
  handleInputChange: PropTypes.func,
  subscribeToNewsletter: PropTypes.func,
  isNewsletterLoaderVisible: PropTypes.bool
};
