import React from 'react';
import Button from '../buttons/buttonWidth.js';
import Tools from '../../services/tools.js';
import PropTypes from 'prop-types';
import '../components.css';

 const CarouselTitle = (props) => {
  return (
    <div id='carouselTitleWrapper' style={{width: 'auto', height: 'auto', position: 'absolute', top: '20%', left: '15%', textAlign: 'left'}}>
        <h1 className="carouselTitleStyle" style={{color: Tools.primaryPink, fontFamily: 'fauneBold', marginBottom: 1}}>{props.title}</h1>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: -40, wrap: "flex-wrap", maxWidth: 700}}>
          <h2 className="carouselTitleStyle" style={{color: 'white', lineHeight: '88px'}}>{props.subtitle1}</h2>
        </div>
      <div style={{display: 'flex', flexDirection: 'row', marginTop: -20}}>
        <Button buttonName='En savoir plus' buttonColor={Tools.primaryGreen} onClick={() => props.displayPage(props.page, 0)} style={{paddingLeft: '45px', paddingRight: '45px'}}/>
      </div>
    </div>
  );
}
export default CarouselTitle;

CarouselTitle.propTypes = {
  title: PropTypes.string,
  subtitle1: PropTypes.string,
  subtitle2: PropTypes.string,
  displayPage: PropTypes.func
};
