import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IMG1 from '../../assets/courses/course1.png';
import IMG2 from '../../assets/courses/course2.png';
import IMG3 from '../../assets/courses/course3.png';
import IMG4 from '../../assets/courses/course4.png';
import IMG5 from '../../assets/courses/course5.png';
import CardContent from './culturalCardContent.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: 250,
    maxWidth: '90%',
    marginTop: '1%',
    marginBottom: '3%',
    textAlign: 'left',
  },
  rootMin: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '80%',
    marginTop: '1%',
    marginBottom: '3%',
    textAlign: 'left',
  }
}));

const CulturalCoursesCardList = (props) => {
  const classes = useStyles();

  return (
    props.displaySmartphoneVersion
    ?
    <Grid container className={classes.rootMin}>
      <Grid item xs={12}>
        <Grid container justify="center">
          {props.courses.map((value, index) => (
            <Grid key={value} item>
                <CardContent
                  index={index}
                  {...props}
                  id={props.courses[index].id}
                  img={props.courses[index].image1}
                  title={props.courses[index].name}
                  subtitle='Lombez, Saint-Elix, Simorre, Villefranche d’Astarac'
                  difficulty={props.courses[index].difficulty.toUpperCase()}
                  time={props.courses[index].time + ' - ' + props.courses[index].distance}
                  description={props.courses[index].description}
                  imgAlt='zero'
                />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
    :
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container spacing={1} style={{justifyContent: 'space-between'}}>
          {props.courses.map((value, index) => (
            <Grid key={value} item>
                <CardContent
                  index={index}
                  {...props}
                  id={props.courses[index].id}
                  img={props.courses[index].image1}
                  title={props.courses[index].name}
                  subtitle='Lombez, Saint-Elix, Simorre, Villefranche d’Astarac'
                  difficulty={props.courses[index].difficulty.toUpperCase()}
                  time={props.courses[index].time + ' - ' + props.courses[index].distance}
                  description={props.courses[index].description}
                  imgAlt='zero'
                />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CulturalCoursesCardList;

CulturalCoursesCardList.propTypes = {
  handleCourseCardClicked: PropTypes.func
};
