import React from 'react';
import Button from '../buttons/buttonWidth.js';
import Modal from 'react-awesome-modal';
import Tools from '../../services/tools.js';
import IMG1 from '../../assets/cycleNetwork/1.png';
import IMG2 from '../../assets/cycleNetwork/2.png';
import IMG3 from '../../assets/cycleNetwork/3.png';
import PropTypes from 'prop-types';
import '../components.css';

const ExportModal = (props) => {
  return (
    props.isExportModalVisible ?
    <div>
      <Modal
        visible={props.isExportModalVisible}
        width="550"
        height="300"
        effect="fadeInDown"
        onClickAway={() => props.handleExportModalState()}
      >
      {
        props.displaySmartphoneVersion
        ?
        <div style={{backgroundColor: 'transparent', border: '2px solid #70398a', borderRadius: 5}}>
          <h1 style={{color: Tools.primaryPurple, fontSize: '1.5em'}}>EXPORTER</h1>
          <div className='pointer' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '4em'}}>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: '5em'}}>
              <img src={IMG1} alt='' style={{width: '52%', marginLeft: 'auto', marginRight: 'auto'}}/>
              <p>Google Maps</p>
            </div>
            <div className='pointer' style={{display: 'flex', flexDirection: 'column', marginLeft: 40, marginRight: 40}}>
              <img src={IMG2} alt='' style={{marginLeft: 'auto', marginRight: 'auto'}}/>
              <p>Email</p>
            </div>
            <div className='pointer' style={{display: 'flex', flexDirection: 'column', marginRight: '5em'}}>
              <img src={IMG3} alt='' style={{marginLeft: 'auto', marginRight: 'auto'}}/>
              <p>Télécharger</p>
            </div>
          </div>
          <div style={{marginTop: 100}}>
            <Button buttonColor={Tools.primaryPurple} buttonName='EXPORTER LE TRAJET' width='100%' onClick={() => alert('EXPORT')}/>
          </div>
        </div>
        :
        <div style={{width: '100%', height: '100%', backgroundColor: 'transparent', border: '2px solid #70398a', borderRadius: 5}}>
          <h1 style={{color: Tools.primaryPurple, fontSize: '1.5em'}}>EXPORTER</h1>
          <div className='pointer' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '4em'}}>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: '5em'}}>
              <img src={IMG1} alt='' style={{width: '52%', marginLeft: 'auto', marginRight: 'auto'}}/>
              <p>Google Maps</p>
            </div>
            <div className='pointer' style={{display: 'flex', flexDirection: 'column'}}>
              <img src={IMG2} alt='' style={{marginLeft: 'auto', marginRight: 'auto'}}/>
              <p>Email</p>
            </div>
            <div className='pointer' style={{display: 'flex', flexDirection: 'column', marginRight: '5em'}}>
              <img src={IMG3} alt='' style={{marginLeft: 'auto', marginRight: 'auto'}}/>
              <p>Télécharger</p>
            </div>
          </div>
          <div style={{marginTop: 100}}>
            <Button buttonColor={Tools.primaryPurple} buttonName='EXPORTER LE TRAJET' width='100%' onClick={() => alert('EXPORT')}/>
          </div>
        </div>
      }
      </Modal>
    </div>
    : null
  );
}

export default ExportModal;

ExportModal.propTypes = {
  isExportModalVisible: PropTypes.bool,
  handleExportModalState: PropTypes.func
};
