import React from 'react';
import PropTypes from 'prop-types';
import Item1 from './carouselItem1.js';
import Item2 from './carouselItem2.js';
import Dot from './carouselDot.js';
import '../components.css';

 const Carousel = (props) => {
  return (
    <div style={{position: 'relative', width: '100%', height: 'auto'}}>
      {
        props.location === "/accueil"
        ?
        props.slide === 1
        ?
        <div>
          <Item1 {...props} />
        </div>
        :
        <div >
          <Item2 {...props} />
        </div>
        :
        null
      }
    </div>
  );
}
export default Carousel;

Carousel.propTypes = {

};
