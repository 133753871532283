import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../services/tools.js';
import Location1 from '../../assets/cards/location.png';
import Location2 from '../../assets/cards/location2.png';
import Slider from 'infinite-react-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import '../components.css';

 const PictureBlocForArt = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div>
      <Slider arrows={false}>
        {[props.img1, props.img2, props.img3].map((value, index) => (
          <div>
            <img className='pointer' src={value} alt='' style={{width: '100%', height: 'auto'}}/>
          </div>
        ))}
      </Slider>
      <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
        <h2 style={{color: Tools.primaryPurple, textAlign: "left", marginLeft: 20, fontSize: 30}}>{props.title}</h2>
        <div style={{display: "flex", flexDirection: 'row', marginLeft: 15, marginTop: -25}}>
          <img src={Location1} alt='subtitle 1' style={{height: '1.5em', marginTop: 'auto', marginBottom: 'auto', marginRight: '0.3em'}}/>
          <p style={{color: Tools.primaryPink, fontWeight: "550"}}>{props.address}</p>
        </div>
        <div style={{display: "flex", flexDirection: 'row', marginLeft: 15, marginTop: -25}}>
          <img src={Location2} alt='subtitle 1' style={{height: '1.5em', marginTop: 'auto', marginBottom: 'auto', marginRight: '0.3em'}}/>
          <p style={{color: Tools.primaryGreen, fontWeight: "550"}}>Itinéraire de la Save</p>
        </div>
      </div>
    </div>
    :
    <div id='pictureBlocWrapper' style={{marginTop: '2em'}}>
      <img src={props.img1} alt='img 1 presentation' style={{width: '70%', maxHeight: '46em', borderBottomLeftRadius: '20px', borderTopRightRadius: '0px'}}/>
      <div style={{marginLeft: '1em', display: 'flex', flexDirection: 'column', width: "30%"}}>
        <img src={props.img2} alt='img 2 presentation' style={{marginBottom: '0.8em', maxHeight: '22em', height: '49%'}}/>
        <img src={props.img3} alt='img 3 presentation' style={{maxHeight: '22em', borderBottomRightRadius: '20px', height: '49%'}}/>
      </div>
    </div>
  );
}
export default PictureBlocForArt;

PictureBlocForArt.propTypes = {
  img1: PropTypes.string,
  img2: PropTypes.string,
  img3: PropTypes.string,
};
