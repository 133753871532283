import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Tools from '../../../services/tools.js';
import Marker from './marker.js';
import {markerStyle} from './markerStyle.js';
const apiKey = "AIzaSyCE45gMW00EYHHZmPwlCuk7prsbaIYOK2M";

const center = {
  lat: 43.72751823614291,
  lng: 0.8769446909089605
};

const VaeMap = (props) =>  {
    return (
      <div style={{display: "flex", justifyContent: "center", marginTop: "5em", marginBottom: "4em"}}>
        <div style={{ height: '60vh', width: '70%'}}>
          <GoogleMapReact
            bootstrapURLKeys={{key: apiKey}}
            defaultCenter={center}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals
          >
          {props.spots.map((row, index) => (
            <Marker
              website={props.spots[index].website}
              markerId={props.spots[index].pos}
              lat={props.spots[index].lat}
              lng={props.spots[index].lng}
              index={index}
              origin={props.origin}
              destination={props.destination}
              clickOnMarker={props.clickOnMarker}
            />          ))}
          </GoogleMapReact>
        </div>
      </div>
    );
}
export default VaeMap;

VaeMap.propTypes = {

};
