import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Tools from '../../services/tools.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    "& .MuiStepIcon-root": {
      color: Tools.primaryPurple
    },
  },

}));

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const steps = props.stages;
  const last = props.stages.length - 1;

  return (
    <div className={classes.root}>
      <Stepper activeStep={0} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel style={{display: 'flex', textAlign: 'left'}}>
              <span style={index === 0 || index === last ? {color: 'black', fontSize: '1.1em', fontWeight: "bold"} : {color: 'black', fontSize: '1.1em'}}>
                {label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
