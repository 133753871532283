import React from 'react';
import PageTitle from '../components/titles/screenTitle.js';
import AppBar from '../components/appBar/appBar.js';
import Footer from '../components/footer/footer.js';
import Map from '../assets/vae/map.png';
import MapMin from '../assets/vae/mapMin.png';
import InteractiveMap from '../components/map/vaeMap/vaeMap.js';
import PriceBloc from '../components/blocks/vae/priceBloc.js';
import BottomBloc from '../components/blocks/vae/bottomBloc.js';
import * as Services from '../services/apiServices.js';
import { withRouter} from "react-router-dom";
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import './screens.css';

//spots of interest displayed on map
const tourristOffice = [
  {lat: 43.626556805420364, lng: 0.8787070804262785, website: 'http://www.tourisme-3cag-gers.com/'},
  {lat: 43.615216273805835, lng: 1.0735468270528468, website: 'https://www.tourisme-gascognetoulousaine.fr/'},
  {lat: 43.487264625143375, lng: 0.9252531178555726, website: 'https://www.mileade.com/'},
  {lat: 43.492645760363345, lng: 0.9313603614076353, website: 'http://lebureausamatanais.fr/'},
  {lat: 43.72225791765404, lng: 0.9778834810409232, website: 'https://www.tourisme-bastidesdelomagne.fr/'},
  {lat: 43.730881389525, lng: 0.8780381423998289, website: 'https://www.tourisme-bastidesdelomagne.fr/'},
  {lat: 43.77422406841672, lng: 0.9292621068339683, website: 'https://www.tourisme-bastidesdelomagne.fr/'},
  {lat: 43.89223967531043, lng: 0.7704501030544968, website: ''},
  {lat: 43.98199756693026, lng:  0.49764644541557634, website: 'https://la-romieu.fr/'},

];

class Vae extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0,
      screenHeight: 0,
      isLoading: true,
      destination: "",
      origin: "",
      googleMap: "",
      googleMaps: "",
      isNewsletterLoaderVisible: false,
      displaySmartphoneVersion: false,
      prices: {}
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();

    const prices = await Services.getVaePrices();
    //console.log('Vae prices');
    // /console.log(prices);
    this.setState({prices: prices});
    this.setState({isLoading: false});
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
  }

  //used to display new page on screen
  _displayPage = (page, id) => {
    const url = getScreenComponent(page);
    this.props.history.push(url);
  }

  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  _handleLoaderState = () => {
    const currentState = this.state.isNewsletterLoaderVisible;
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  render() {
    return(
      this.state.isLoading ? <div style={{display: "flex", justifyContent: "center", marginTop: "50vh", transform: "translateY(-50%)"}}class="lds-circle"><div></div></div> :
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <PageTitle
            {...this.state}
            title='Louer un VAE*'
            subtitle="Dans une démarche de slowtourisme, le Pays Portes de Gascogne a développé un service de location de Vélos à Assitance Electrique. Ils permettent de se déplacer sur les petits chemins vallonnés du territoire sans effort !"
            thirdTitle="Vous pouvez retrouver nos 11 points de location sur la carte ci-dessous"
            lastTitle='*vélo à assistance électrique'
          />
          <InteractiveMap
            spots={tourristOffice}
            {...this.state}
            />
          <PriceBloc prices={this.state.prices} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <BottomBloc displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (Vae);
