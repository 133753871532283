import React from 'react';
import PropTypes from 'prop-types';
import IMG1 from '../../assets/icons/part1.png'
import IMG2 from '../../assets/icons/part2.png'
import IMG3 from '../../assets/icons/part3.png'
import IMG4 from '../../assets/icons/part4.png'
import IMG5 from '../../assets/icons/part5.png'
import IMG6 from '../../assets/icons/part6.png'
import IMG7 from '../../assets/icons/part7.png'

import '../components.css';

const PartnersItem = (props) => {
  return (
    <div className='footerItem' style={props.screenWidth > 737 ? {width: '20%', height: "15em"} : {width: '20%'}}>
      {/*partners title row*/}
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {/*partners title*/}
        <h3 className='colorWhite' style={{fontFamily: 'fauneBold'}}>{props.title}</h3>
      </div>
      {/*partners icon first row*/}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 14}}>
        {/*partners icon 1*/}
        <img className='footerImg' src={IMG1} alt='partenaire 1'/>
        {/*partners icon 2*/}
        <img className='footerImg' src={IMG2} alt='partenaire 2' />
        {/*partners icon 3*/}
        <img className='footerImg' src={IMG3} alt='partenaire 3' />
      </div>
      {/*partners icon second row*/}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.3em'}}>
        {/*partners icon 4*/}
        <img className='footerImg' src={IMG4} alt='partenaire 4' />
        {/*partners icon 5*/}
        <img className='footerImg' src={IMG5} alt='partenaire 5' />
      </div>
      {/*partners icon third row*/}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.3em'}}>
        {/*partners icon 6*/}
        <img className='footerImg' src={IMG6} alt='partenaire 6' />
        {/*partners icon 7*/}
        <img className='footerImg' src={IMG7} alt='partenaire 7' />
      </div>
    </div>
  );
}

export default PartnersItem;

PartnersItem.propTypes = {
  title: PropTypes.string,
};
