import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Tools from '../../services/tools.js';
import MoreHorizIcon from '@material-ui/icons/MoreVert';
import Marker from './marker.js';
import Menu from '../menu/mapMenu.js';

const apiKey = "AIzaSyCE45gMW00EYHHZmPwlCuk7prsbaIYOK2M";

const center = {
  lat: 43.6911111,
  lng: 0.9158333333333334
};

const centerMin = {
  lat: 43.6911111,
  lng: 0.9158333333333334
};

const handleApiLoaded = (map, maps, props) => {
  props.setMapService(map, maps);
};

const SimpleMap = (props) =>  {
    return (
      <div style={props.displaySmartphoneVersion ? {height: '50em', width: '100%', marginTop: '3em', marginBottom: '3em', position: 'relative'} : {height: '50em', width: '72%', marginLeft: '15%', marginRight: '15%', marginTop: '3em', marginBottom: '3em', position: 'relative'}}>
        {/*Menu burger item*/}
        {props.displaySmartphoneVersion
          ?
          <div>
            <p onClick={() => props.handleMenuClick()} style={{marginTop: -60, position: "absolute", right: 20, color: Tools.primaryPink}}>{props.isMapMenuOpen ? "Cacher" : "Afficher"}</p>
            {props.isMapMenuOpen ? <Menu orange={Tools.primaryOrange} {...props}/> : null}
            </div>
          :
          <div style={{width: '3em', height: '3em', backgroundColor: Tools.primaryOrange, position: 'absolute', top: 60, right: 8, zIndex: 2, borderRadius: '50%'}}>
            {<MoreHorizIcon className='pointer' onClick={() => props.handleMenuClick()} style={{color: 'white', display: 'tableCell', verticalAlign: 'middle', minHeight: '1.5em', fontSize: '2em'}}/>}
            {props.isMapMenuOpen ? <Menu orange={Tools.primaryOrange} {...props}/> : null}
          </div>
        }
        <GoogleMapReact
          bootstrapURLKeys={{key: apiKey}}
          defaultCenter={props.displaySmartphoneVersion ? center : centerMin}
          defaultZoom={12}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps, props)}
        >
          {props.spots.map((row, index) => (
            <Marker
              type={null}
              markerId={props.spots[index].pos}
              lat={props.spots[index].latitude}
              lng={props.spots[index].longitude}
              index={index}
              origin={props.origin}
              destination={props.destination}
              clickOnMarker={props.clickOnMarker}
            />
          ))}
          {props.pointOfInterest.map((row, index) => (
            <Marker
              lat={props.pointOfInterest[index].latitude}
              lng={props.pointOfInterest[index].longitude}
              type={props.pointOfInterest[index].type}
              index={index}
              origin={null}
              destination={null}
              clickOnMarker={null}
              markerId={null}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
}
export default SimpleMap;

SimpleMap.propTypes = {
  spots: PropTypes.array,
  setMapService: PropTypes.func,
  clickOnMarker: PropTypes.func,
  origin: PropTypes.object,
  destination: PropTypes.object,
};
