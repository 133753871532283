import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../services/tools.js';
import TimerIcon from '@material-ui/icons/Timer';
import HeightIcon from '@material-ui/icons/Height';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import Slider from 'infinite-react-carousel';

import '../components.css';

 const PictureBloc = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div id='' style={{}}>

      <Slider arrows={false} style={{width: "100%"}}>
        {[props.img1, props.img2, props.img3].map((value, index) => (
          <div>
            <img className='pointer' src={value} alt='' style={{width:"100%", height: 'auto', paddingRight: "40px"}}/>
          </div>
        ))}
      </Slider>

      <h2 style={{color: Tools.primaryPurple, display: "flex", textAlign: "left", paddingLeft: "40px", paddingRight: "40px"}}>{props.title}</h2>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: '10%', marginRight: '10%'}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: 60}}>
            <TimerIcon />
            <p style={{}}>{props.courseTime}</p>
          </div>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: 60}}>
            <HeightIcon id='distanceIcon'/>
            <p style={{}}>{props.courseDist}</p>
          </div>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: 60}}>
            <DirectionsBikeIcon id='bikeIcon'/>
            <p style={{}}>{props.difficulty}</p>
          </div>
        </div>
        <hr />
    </div>
    :
    <div id='pictureBlocWrapper' style={{marginTop: '2em'}}>
      <img src={props.img1} alt='img 1 presentation' style={{width: '70%', maxHeight: '46em', borderBottomLeftRadius: '20px', borderTopRightRadius: '0px'}}/>
      <div style={{marginLeft: '1em', display: 'flex', flexDirection: 'column', width: "30%"}}>
        <img src={props.img2} alt='img 2 presentation' style={{marginBottom: '0.8em', maxHeight: '22em', height: '49%'}}/>
        <img src={props.img3} alt='img 3 presentation' style={{maxHeight: '22em', borderBottomRightRadius: '20px', height: '49%'}}/>
      </div>
    </div>
  );
}
export default PictureBloc;

PictureBloc.propTypes = {
  img1: PropTypes.string,
  img2: PropTypes.string,
  img3: PropTypes.string,
};
