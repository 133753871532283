import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../services/tools.js';
import '../switch.css';

const Slider = (props) => {
  return (
    <label className='switch pointer'>
      {props.switchState === 'liste' ? <span id='switchTitleList'>LISTE</span> : <span id='switchTitleCard'>CARTE</span>}
      <input type='checkbox' unchecked onClick={() => props.handleSwitchState()} />
      <span style={props.switchState === 'liste' ? {backgroundColor: Tools.primaryPink} : {backgroundColor: Tools.primaryGreen}} className='slider round'></span>
    </label>
  );
}

export default Slider;

Slider.propTypes = {
  switchState: PropTypes.string,
  handleSwitchState: PropTypes.func
};
