import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../services/tools.js';
import TimerIcon from '@material-ui/icons/Timer';
import HeightIcon from '@material-ui/icons/Height';
import '../components.css';

const SimpleTitleWithTimer = (props) => {
  return (
    props.displaySmartphoneVersion ? null :
    <div className='simpleTitleWrapper' style={{marginTop: '5em'}}>
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', paddingLeft: 20, width: 'auto', marginTop: -40}}>
        <h1 className='pageTitle' style={{color: Tools.primaryPurple}}>{props.title}</h1>
        <div style={{width: '100%', height: '3em', marginTop: '-2.5em', display: 'flex', flexDirection: 'row'}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <TimerIcon style={{color: '#525252'}}/>
            <p style={{marginLeft: '0.5em', marginRight: '0.5em', color: '#525252'}}>{props.courseTime} | </p>
            <HeightIcon id='distanceIcon' style={{color: '#525252'}}/>
            <p style={{marginLeft: '0.5em', marginRight: '0.5em', color: '#525252'}}>{props.courseDist}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleTitleWithTimer;

SimpleTitleWithTimer.propTypes = {
  title: PropTypes.string,
  courseTime: PropTypes.string,
  courseDist: PropTypes.string,
};
