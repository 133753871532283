import React from 'react';
import Tools from '../services/tools.js';
import PageTitle from '../components/titles/screenTitle.js';
import AppBar from '../components/appBar/appBar.js';
import Footer from '../components/footer/footer.js';
import Modal from '../components/modals/exportModal.js';
import Map from '../components/map/map.js';
import * as Services from '../services/apiServices.js';
import { withRouter} from "react-router-dom";
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import './screens.css';

class CycleNetwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      screenWidth: 0,
      screenHeight: 0,
      isNewsletterLoaderVisible: false,
      googleMaps: {},
      googleMap: {},
      waypoints: [],
      origin: '',
      destination: '',
      routePolylineWhite: '',
      routePolylinePurple: '',
      isMapMenuOpen: false,
      isExportModalVisible: false,
      displaySmartphoneVersion: false,
      pointOfInterest: [],
      selectedPoint1: null,
      selectedPoint2: null,
      gpxFile: null,
      spots: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
    this.setState({isLoading: true});
    this._getDataFromApi();
  }

  _getDataFromApi = async () => {
    let value = await Services.getAllPoiForMap();
    console.log('POI')
    console.log(value);
    this.setState({pointOfInterest: value});
    value = await Services.getAllCycleNetworkPoi();
    console.log('Cycle network points');
    console.log(value);
    this.setState({spots: value}, function() {
      this.setState({isLoading: false});
    });
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
  }

  //used to display new page on screen
  _displayPage = (page, id) => {
    const url = getScreenComponent(page, id);
    this.props.history.push(url);
  }

  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  //called to change the newslatter state
  _handleLoaderState = () => {
    const currentState = this.state.isNewsletterLoaderVisible;
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  //called to set the google map services as state
  _setMapService = (map, maps) => {
    this.setState({googleMap: map});
    this.setState({googleMaps: maps});
  }

  //called to remove polylines from google map
  _resetMap() {
    this.state.routePolylineWhite.setMap(null);
    this.state.routePolylinePurple.setMap(null);
  }

  //called when user clicks on marker on google map
  _clickOnMarker = async (index) => {
    console.log('Index : ' + index);
    console.log(this.state.spots[index]);

    //check if user click on desination marker
    if (this._doesUserClickOnWaypoint(index) === true) {
      alert('true')
      this.setState({selectedPoint1: null});
      this.setState({selectedPoint2: null});
      this.setState({waypoints: null});
      this._resetMap();
    }

    //set first point
    if (this.state.selectedPoint1 === null) {
      this.setState({selectedPoint1: this.state.spots[index]}, function() {
        console.log('Selected pt 1');
        console.log(this.state.selectedPoint1);
      });
    }
    //set second point and calculate gpx
    else if (this.state.selectedPoint2 === null) {
      this.setState({selectedPoint2: this.state.spots[index]}, async function() {
        console.log('Selected pt 2');
        console.log(this.state.selectedPoint2);
        const pts = [this.state.selectedPoint1, this.state.selectedPoint2];
        const ret = await Services.getGpxBetweenPoints(pts);
        console.log('simple cicle network points');
        console.log(ret);
        if (ret == -1) {
          alert('Aucun trajet disponible entre ces points');
          this.setState({origin: ''});
          this.setState({destination: ''});
          return;
        }
        this._handleWaypoints(ret);
        this._handlePathDrawing();
      });
    }
    else {
      //check if user click on origin Marker
      if (this._doesPointsAreIdentical(this.state.selectedPoint1, this.state.spots[index]) === true) {
        this.setState({selectedPoint1: null});
        this.setState({selectedPoint2: null});
        this.setState({waypoints: null});
        this._resetMap();
      }
      //check if user click on destination Marker
      else if (this._doesPointsAreIdentical(this.state.selectedPoint2, this.state.spots[index]) === true) {
        this.setState({selectedPoint1: null});
        this.setState({selectedPoint2: null});
        this.setState({waypointsApi: null});
        this._resetMap();
      }
      //user click on a new marker
      else {
        if (this._doesUserClickOnWaypoint(index) === true) {
          this.setState({selectedPoint1: null});
          this.setState({selectedPoint2: null});
          this.setState({waypointsApi: null});
          this._resetMap();
        }
        const waypoints = this._addPreviousDestinationToWaypointArray();
        this._resetMap();
        const pts = this._handleMultipleWaypointsList(index);
        const ret = await Services.getGpxBetweenPoints(pts);
        console.log('multiple cycle network points')
        console.log(ret);
        if (ret == -1) {
          alert('Aucun trajet disponible entre ces points');
          this.setState({origin: ''});
          this.setState({destination: ''});
          return;
        }
        this._handleWaypoints(ret);
        this._handlePathDrawing();
      }
    }
  }

  _doesUserClickOnWaypoint(index) {
    for (let i = 0; i < this.state.waypoints.length; i++) {
      console.log(this.state.waypoints[i].latitude + " check if is equal to : " + this.state.spots[index].latitude);
      console.log(this.state.waypoints[i].longitude + " check if is equal to : " + this.state.spots[index].longitude);
      if (this.state.waypoints[i].latitude === this.state.spots[index].latitude && this.state.waypoints[i].longitude === this.state.spots[index].longitude) {
        return true;
      }
    }
    return false;
  }

  _handleMultipleWaypointsList(index) {
    let ret = [];
    ret.push(this.state.selectedPoint1);
    for (let i = 0; i < this.state.waypoints.length; i++) {
      console.log(this.state.waypoints[i])
      ret.push(this.state.waypoints[i]);
    }
    this.setState({selectedPoint2: this.state.spots[index]});
    ret.push(this.state.spots[index]);
    return ret;
  }

  _handleWaypoints(waypoints) {
    const size = waypoints.length;
    let waypointsList = this.state.waypoints;

    for (let i = 1; i < size; i++) {
      if (i == 0) {
        this.setState({origin: waypoints[0]});
      }
      if (i == size - 1) {
        this.setState({destination: waypoints[size - 1]});
      }
      else {
        const location = {
          lat: waypoints[i].lat,
          lng: waypoints[i].lng,
        }
        const tmp = {
          location: location,
          stopover: true
        }
        waypointsList.push(tmp);
      }
      this.setState({waypoints: waypointsList}, function() {
        console.log(this.state.waypoints)
      });
    }
    if (this.state.origin == "") {
      this.setState({origin: waypoints[0]});
    }
  }

  _doesPointsAreIdentical(pt1, pt2) {
    if (pt1.latitude === pt2.latitude && pt1.longitude === pt2.longitude) {
      return true;
    }
    return false;
  }

  //check if user click on marker that is present into waypoints state
  _isUserClickOnWaypointMarker(index) {
    const selectedMarker = this.state.spots[index];
    for (let i = 0; i < this.state.waypoints.length; i++) {
      //selected marker found into waypoint list
      if (this._doesPointsAreIdentical(selectedMarker, this.state.waypoints[i]) === true) {
        //return selected marker index
        return i;
      }
    }
    //selected marker not found into waypoints list, return -1
    return -1;
  }


  //called to add last destination to waypoint list
  _addPreviousDestinationToWaypointArray() {
    //this.setState({waypoints: waypoints});
    let waypoints = this.state.waypoints;
    waypoints.push(this.state.selectedPoint2);
    this.setState({waypoints: waypoints});
    //return new waypoint list
    return waypoints;
  }

  //try to draw line from origin to destination state
  _handlePathDrawing() {
    //origin or destination is not defined yet
    if (this.state.origin === '' || this.state.destination === '') {
      return;
    }
    console.log('Aaazeze')
    console.log(this.state.waypoints)
    //google map api call to draw new route
    const directionsService = new this.state.googleMaps.DirectionsService();
    directionsService.route({
      origin: this.state.origin,
      destination: this.state.destination,
      waypoints: this.state.waypoints,
      travelMode: 'BICYCLING'
    },
    (response, status) => {
      //api call success
      if (status === 'OK') {
        this._drawRoute(response);
      }
      //api call failure
      else {
        window.alert(status);
      }
    });
  }

  //draw polyline route on google map
  _drawRoute(response) {
    const directionsDisplay = new this.state.googleMaps.DirectionsRenderer();
    directionsDisplay.setDirections(response);
    //polyline border purple
    let routePolyline = new this.state.googleMaps.Polyline({
      path: response.routes[0].overview_path,
      strokeColor: Tools.primaryPurple,
      strokeOpacity: 1.0,
      strokeWeight: 8,
    });
    routePolyline.setMap(this.state.googleMap);
    this.setState({routePolylinePurple: routePolyline});
    //polyline content white
    routePolyline = new this.state.googleMaps.Polyline({
      path: response.routes[0].overview_path,
      strokeColor: 'white',
      strokeOpacity: 1.0,
      strokeWeight: 4,
    });
    routePolyline.setMap(this.state.googleMap);
    this.setState({routePolylineWhite: routePolyline});
  }

  _handleMenuClick = () => {
    const currentState = this.state.isMapMenuOpen;
    this.setState({isMapMenuOpen: !currentState});
  }

  _exportButtonClicked = () => {
    if (this.state.origin === '' || this.state.destination === '') {
      alert("Merci de définir un trajet pour pouvoir l'exporter");
    }
    else {
      this._handleExportModalState();
    }
  }

  _handleExportModalState = () => {
    const currentState = this.state.isExportModalVisible;
    this.setState({isExportModalVisible: !currentState});
  }

  render() {
    return(
      this.state.isLoading ? <div style={{display: "flex", justifyContent: "center", marginTop: "50vh", transform: "translateY(-50%)"}}class="lds-circle"><div></div></div> :
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <Modal displaySmartphoneVersion={this.state.displaySmartphoneVersion} isExportModalVisible={this.state.isExportModalVisible} handleExportModalState={this._handleExportModalState}/>
          <PageTitle
            displaySmartphoneVersion={this.state.displaySmartphoneVersion}
            title='Réseau cyclable'
            subtitle="Grâce à la carte ci-dessous, vous pouvez créer vous-même votre parcours. Il vous suffit de cliquer sur les points pour créer votre itinéraire."/>
          <Map
            spots={this.state.spots}
            clickOnMarker={this._clickOnMarker}
            setMapService={this._setMapService}
            destination={this.state.destination}
            handleMenuClick={this._handleMenuClick}
            exportButtonClicked={this._exportButtonClicked}
            displaySmartphoneVersion={this.state.displaySmartphoneVersion}
            {...this.state}
            />
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (CycleNetwork);
