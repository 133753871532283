import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tools from '../../../services/tools.js';
import '../../components.css';

 const BottomBlock = (props) => {
  return (
    <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%'}}>
      <p style={{marginBottom: '2em', color: 'dimgrey'}} className='bottomText ralewaySemibold'>Pour voir des œuvres en résonnance avec l’environnement, c’est par ici !</p>
      <Button
        variant="contained"
        onClick={() => props.displayPage('art', 0)}
        style={{backgroundColor: Tools.primaryGreen, color: 'white', width: props.width, marginBottom: '1%', paddingLeft: 32, paddingRight: 32, height: 40, fontWeight: 'bold', marginBottom: '5em'}}
      >
      VOIR LES OEUVRES ART & ENVIRONNEMENT
      </Button>
    </div>
  );
}
export default BottomBlock;

BottomBlock.propTypes = {
  displayPage: PropTypes.func
};
