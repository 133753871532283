import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../services/tools.js';
import Button from '@material-ui/core/Button';
import ButtonWidth from '../buttons/buttonWidth.js';
import Reco from '../../assets/save/reco.png';
import '../components.css';

 const BottomInformation = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div id='' style={{padding: "40px", marginTop: -50}}>
      <div style={{textAlign: "left"}}>
        <h2 className='fauneBold' style={{color: Tools.primaryPurple}}>Informations pratiques</h2>
        <p className='ralewayLight' style={{lineHeight: '25px'}}>{props.information}</p>
        <h2 className='fauneBold' style={{color: Tools.primaryPurple, marginTop: '3em'}}>Recommandations</h2>
        <p className='ralewayLight' style={{lineHeight: '25px'}}>Vous souhaitez faire une escale pour vous restaurer ou vous hydrater ? Retrouvez les lieux que nous vous conseillons en
        <span> </span> <span className='pointer' onClick={() => props.openTouristOfficePage()} style={{color: Tools.primaryPurple, textDecoration: "underline"}}>cliquant ici</span></p>
      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5em', marginBottom: '5em'}}>
        <ButtonWidth buttonName='EXPORTER LE TRAJET' onClick={() => alert('export')} buttonColor={Tools.primaryGreen}/>
      </div>
    </div>
    :
    <div id='bottomInformationWrapper' style={{marginTop: '5em'}}>
      <div style={{width: '50%'}}>
        <h2 className='fauneBold' style={{color: Tools.primaryPurple}}>Informations pratiques</h2>
        <p className='ralewayLight' style={{lineHeight: '25px'}}>{props.information}</p>
        <h2 className='fauneBold' style={{color: Tools.primaryPurple, marginTop: '3em'}}>Recommandations</h2>
        <p className='ralewayLight' style={{lineHeight: '25px'}}>Vous souhaitez faire une escale pour vous restaurer ou vous hydrater ? Retrouvez les lieux que nous vous conseillons en
          <span> </span> <span className='pointer' onClick={() => props.openTouristOfficePage()} style={{color: Tools.primaryPurple, textDecoration: "underline"}}>cliquant ici</span></p>

      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5em', marginBottom: '5em'}}>
        <Button
          variant="contained"
          onClick={() => props.exportPdf()}
          style={{backgroundColor: Tools.primaryGreen, color: 'white', width: "20%", minWidth: "200px", marginBottom: '1%', height: 40, fontWeight: 'bold'}}
        >
        EXPORTER LE TRAJET
        </Button>
      </div>
    </div>
  );
}
export default BottomInformation;

BottomInformation.propTypes = {
  handleLinkClicked: PropTypes.func
};
