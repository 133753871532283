import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tools from '../../services/tools.js';
import CardContent from './cardContentForArtItem.js';
import Grid from '@material-ui/core/Grid';

export default function ArtCardListForArtItem(props) {
  return (
    props.displaySmartphoneVersion
    ?
    <div style={{}}>
      <h2 className='fauneBold' style={{color: Tools.primaryPurple, paddingLeft: "40px", textAlign: "left", height: "5em"}}>{props.category === "Fresques" ? "Dans la catégorie Fresques" : "Dans la catégorie Art & Environnement"}</h2>
      <div style={{display: 'flex', flexDirection: 'column', paddingLeft: "40px", paddingRight: "40px"}}>
        {props.lastPoi.map((value, index) => (
          <CardContent
            index={index}
            img={props.lastPoi[index].image1}
            id={props.lastPoi[index].id}
            title={props.lastPoi[index].name}
            subtitle={props.lastPoi[index].city}
            titleColor={Tools.primaryGreen}
            descriptionColor='black'
            cardImg={props.lastPoi[index].flagImage}
            description={props.lastPoi[index].description}
            imgAlt=''
            {...props}
            />
        ))}
      </div>
    </div>
    :
    <div style={{marginTop: '5em'}}>
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', width: '70%'}}>
          <h2 className='fauneBold' style={{color: Tools.primaryPurple}}>{props.category === "Fresques" ? "Dans la catégorie Fresques" : "Dans la catégorie Art & Environnement"}</h2>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '70%', justifyContent: "space-between"}}>
          {props.lastPoi.map((value, index) => (
            <CardContent
              index={index}
              img={props.lastPoi[index].image1}
              id={props.lastPoi[index].id}
              title={props.lastPoi[index].name}
              subtitle={props.lastPoi[index].city}
              titleColor={Tools.primaryGreen}
              descriptionColor='black'
              cardImg={props.lastPoi[index].flagImage}
              description={props.lastPoi[index].description}
              imgAlt=''
              {...props}
              />
          ))}
        </div>
      </div>
    </div>
  );
}
