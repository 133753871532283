import React from 'react';
import PropTypes from 'prop-types';
import SimpleButton from '../../../components/buttons/smallButton.js';
import ButtonWidth from '../../../components/buttons/buttonWidth.js';
import Tools from '../../../services/tools.js';
import '../../components.css';

 const BottomBlock = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div style={{width: '70%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%', marginTop: 30}}>
      <p style={{marginBottom: '2em', color: 'dimgrey'}} className='ralewaySemibold'>Aucun de ces parcours ne correspond à vos envies ? Créez votre propre parcours !</p>
      <ButtonWidth width='100%' buttonName='CRÉER MON PARCOURS' onClick={() => props.displayPage('cycleNetwork', 0)} buttonColor={Tools.primaryGreen}/>
    </div>
    :
    <div style={{width: '31%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '5em', marginTop: '5em'}}>
      <p style={{marginBottom: '2em', color: 'dimgrey'}} className='bottomText ralewaySemibold'>Aucun de ces parcours ne correspond à vos envies ? Créez votre propre parcours !</p>
      <SimpleButton buttonName='CRÉER MON PARCOURS' onClick={() => props.displayPage('cycleNetwork', 0)} buttonColor={Tools.primaryGreen}/>
    </div>
  );
}
export default BottomBlock;

BottomBlock.propTypes = {
  displayPage: PropTypes.func,
};
