import React from 'react';
import PropTypes from 'prop-types';
import '../components.css';

const InformationItem = (props) => {
  return (
    <div className='footerItem' style={props.screenWidth > 737 ? {width: '20%', height: "15em"} : {width: '20%'}}>
      {/*information title row*/}
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {/*information title*/}
        <h3 className='colorWhite' style={{fontFamily: 'fauneBold'}}>{props.title}</h3>
      </div>
      {/*information text row*/}
      <div style={{display: 'flex', flexDirection: 'row', textAlign: 'left'}}>
        <span className='colorWhite footerLine'style={{marginBottom: '-1%'}}>P.E.T.R Pays Porte de Gascogne</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left'}}>
        <span className='colorWhite footerLine' style={{marginBottom: '-1%'}}>85 rue Nationale - BP 15</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left'}}>
        <span className='colorWhite footerLine'style={{marginBottom: '-1%'}}>32200 GIMONT</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left'}}>
        <span className='colorWhite footerLine' style={{marginTop: '1.5em', marginBottom: '-1%'}}>05 62 67 97 10</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left'}}>
        <span className='colorWhite pointer footerLine hoverLineWhite' style={{}}onClick={() => props.displayPage('contact', 0)}>Nous contacter</span>
      </div>
    </div>
  );
}

export default InformationItem;

InformationItem.propTypes = {
  title: PropTypes.string,
  displayPage: PropTypes.func
};
