import React  from 'react';
import PropTypes from 'prop-types';
import {markerStyle} from './markerStyle.js';
import {selectedMarkerStyle} from './selectedMarkerStyle.js';
import Item1 from '../../assets/cycleNetwork/menuItem1.svg';
import Item2 from '../../assets/cycleNetwork/menuItem2.svg';
import Item3 from '../../assets/cycleNetwork/menuItem3.svg';
import Item4 from '../../assets/cycleNetwork/menuItem4.svg';
import Item5 from '../../assets/cycleNetwork/menuItem5.svg';
import Item6 from '../../assets/cycleNetwork/menuItem6.svg';
import Item7 from '../../assets/cycleNetwork/menuItem7.svg';
import Item8 from '../../assets/cycleNetwork/menuItem8.svg';
import Item9 from '../../assets/cycleNetwork/menuItem9.svg';
import Item10 from '../../assets/cycleNetwork/menuItem10.svg';
import Item11 from '../../assets/cycleNetwork/menuItem11.svg';
import Item12 from '../../assets/cycleNetwork/menuItem12.svg';
import Item13 from '../../assets/cycleNetwork/menuItem13.svg';
import Item14 from '../../assets/cycleNetwork/menuItem14.svg';
import Item15 from '../../assets/cycleNetwork/menuItem15.svg';
import Item16 from '../../assets/cycleNetwork/menuItem16.svg';
import Item17 from '../../assets/cycleNetwork/menuItem17.svg';
import Item18 from '../../assets/cycleNetwork/menuItem18.svg';
import Item19 from '../../assets/cycleNetwork/menuItem19.svg';
import Item20 from '../../assets/cycleNetwork/menuItem20.svg';
import Item21 from '../../assets/cycleNetwork/menuItem21.svg';

const GetIcon = (type) => {
  switch (type) {
    case 'Fresques murales':
      return Item1;
      break;
    case 'Oeuvres Arts et Environnements':
      return Item2;
      break;
    case 'Offices de Tourisme':
      return Item3;
      break;
    case 'Médiathèques - Bibliothèques':
      return Item4;
      break;
    case 'Librairies':
      return Item5;
      break;
    case 'Cafés culturels':
      return Item6;
      break;
    case 'Lieux culturels':
      return Item7;
      break;
    case 'Aires de pique-nique':
      return Item8;
      break;
    case 'Bases de loisirs':
      return Item9;
      break;
    case 'Toilettes publiques':
      return Item10;
      break;
    case 'Points d’eau potable':
      return Item11;
      break;
    case 'Gares multimodales':
      return Item12;
      break;
    case 'Bornes de recharge VAE':
      return Item13;
      break;
    case 'Points de location Vélos de Pays':
      return Item14;
      break;
    case 'Réparateurs - Entretien vélo':
      return Item15;
      break;
    case 'Mairie':
      return Item16;
      break;
    case 'Points de vue':
      return Item17;
      break;
    case 'Forêts':
      return Item18;
      break;
    case 'Jardins':
      return Item19;
      break;
    case 'Lacs':
      return Item20;
      break;
    case 'Chemins':
      return Item21;
      break;
    default:
      return null;
  }
}

const Marker = (props) => {
  return (
    props.type !== null
    ?
    <div>
      <img src={GetIcon(props.type)} alt="" />
    </div>
    :
    props.origin.lat === props.lat && props.origin.lng === props.lng
    ?
    <div style={selectedMarkerStyle} onClick={() => props.clickOnMarker(props.index)}>
      <span style={{color: "white", position: "absolute", top: 0, left: 4}}>{props.markerId !== 0 ? props.markerId : ""}</span>
    </div>
    : props.destination.lat === props.lat && props.destination.lng === props.lng
    ?
    <div style={selectedMarkerStyle} onClick={() => props.clickOnMarker(props.index)}>
      <span style={{color: "white", position: "absolute", top: 0, left: 4}}>{props.markerId !== 0 ? props.markerId : ""}</span>
    </div>
    :
    <div style={markerStyle} onClick={() => props.clickOnMarker(props.index)}>
      <span style={{color: "#70398a", position: "absolute", top: 0, left: 4}}>{props.markerId !== 0 ? props.markerId : ""}</span>
    </div>
  );
}

export default Marker;

Marker.propTypes = {
  clickOnMarker: PropTypes.func,
  origin: PropTypes.object,
  destination: PropTypes.object,
  index: PropTypes.number
};
