import React from 'react';
import PropTypes from 'prop-types';
import '../components.css';

 const CarouselDot = (props) => {
  return (
    <div>
      {
        props.slide === 1
        ?
        <div style={{display: 'flex', flexDirection: 'row', cursor: 'pointer'}}>
          <div style={{width: '25px', height: '25px'}}>
            <span className='dot' onClick={() => props.slideChange('slide 1')}/>
          </div>
          <div style={{width: '25px', height: '25px'}}>
            <span className='emptyDot' onClick={() => props.slideChange('slide 1')}/>
          </div>
        </div>
        :
        <div style={{display: 'flex', flexDirection: 'row', cursor: 'pointer'}}>
          <div style={{width: '25px', height: '25px'}}>
            <span className='emptyDot' onClick={() => props.slideChange('slide 2')}/>
          </div>
          <div style={{width: '25px', height: '25px'}}>
            <span className='dot' onClick={() => props.slideChange('slide 2')}/>
          </div>
        </div>
      }
    </div>
  );
}
export default CarouselDot;

CarouselDot.propTypes = {

};
