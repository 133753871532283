import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tools from '../../services/tools.js';
import Menu from '../menu/appBarMenu.js';
import Logo from '../../assets/icons/logo.png';
import '../components.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
   marginRight: theme.spacing(2),
 },
 title: {
   flexGrow: 1,
 },
}));

const ButtonAppBar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    {
      props.displaySmartphoneVersion
      ?
      <AppBar position="static">
        <Toolbar style={{backgroundColor: Tools.primaryPurple}}>
          <IconButton onClick={() => props.displayPage('home', 0)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <img src={Logo} alt='logo'/>
          </IconButton>
          <div className='appBarTitleDiv'>
            <span style={{position: 'absolute', right: 16, top: 5}} ><Menu displayPage={props.displayPage}/></span>
          </div>
        </Toolbar>
      </AppBar>
      :
      <AppBar position="static">
        <Toolbar style={{backgroundColor: Tools.primaryPurple}}>
          <IconButton onClick={() => props.displayPage('home', 0)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <img src={Logo} alt='logo'/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
          </Typography>
          <div className='appBarTitleDiv'>
            <span className='appBarTitleText pointer' style={{color: Tools.primaryOrange}} onClick={() => props.displayPage('art', 0)}>ART & ENVIRONNEMENT</span>
          </div>
          <div className='appBarTitleDiv'>
            <span className='appBarTitleText pointer' style={{color: Tools.primaryOrange}} onClick={() => props.displayPage('muralPainting', 0)}>FRESQUES</span>
          </div>
          <div className='appBarTitleDiv'>
            <span className='appBarTitleText pointer' style={{color: Tools.primaryOrange}} onClick={() => props.displayPage('cycleNetwork', 0)}>RÉSEAU CYCLABLE</span>
          </div>
          <div className='appBarTitleDiv'>
            <span className='appBarTitleText pointer' style={{color: Tools.primaryOrange}} onClick={() => props.displayPage('culturalCourses', 0)}>PARCOURS CULTURELS</span>
          </div>
          <div className='appBarTitleDiv'>
            <span className='appBarTitleText pointer' style={{color: Tools.primaryOrange}} onClick={() => props.displayPage('about', 0)}>À PROPOS</span>
          </div>
          <div className='appBarTitleDiv'>
            <span className='appBarTitleText pointer' style={{color: Tools.primaryOrange}} onClick={() => props.displayPage('contact', 0)}>CONTACT</span>
          </div>
        </Toolbar>
      </AppBar>
    }
    </div>
  );
}

export default ButtonAppBar;

ButtonAppBar.propTypes = {
  displayPage: PropTypes.func
};
