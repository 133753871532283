import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PartnersItem from './partnersItem.js';
import InformationItem from './informationItem.js';
import NewsletterItem from './newsletterItem.js';
import FollowusItem from './followusItem.js';
import Tools from '../../services/tools.js';
import '../components.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Tools.primaryPurple,
  },
  footerDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: "2em",
    flexWrap: "wrap"
  },
  subfooter: {
    backgroundColor: Tools.secondaryPurple,
    width: '100%',
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '4%'
  },
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{marginLeft: "15%", marginRight: "15%"}}>
        <div className={classes.footerDiv} style={props.screeWidth > 737 ? {width: '100%', height: 'auto', justifyContent: 'space-between'} : {width: '100%', height: 'auto', justifyContent: 'center'}}>
          <PartnersItem title='Partenaires' {...props}/>
          <InformationItem title='Informations' {...props}/>
          <NewsletterItem title='Newsletter' {...props}/>
          <FollowusItem title='Suivez-nous' {...props}/>
        </div>
      </div>
      <div className={classes.subfooter}>
        <p className='colorWhite' style={{marginTop: 'auto', marginBottom: 'auto', fontSize: '14px'}}>Les itinéraires artistiques - <span id='' className='pointer'>Mentions légales</span></p>
      </div>
    </div>
  );
}

export default Footer;

Footer.propTypes = {
  props: PropTypes.object,
};
