import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Tools from '../../services/tools.js';
import MoreHorizIcon from '@material-ui/icons/MoreVert';
import Marker from './marker.js';
import Menu from '../menu/mapMenu.js';

const InteractiveMap = (props) =>  {
    return (
      <div style={{border: "1px solid black", width: "70%", marginLeft: "auto", marginRight: "auto"}}>
        <div id="mapplic"></div>
      </div>
    );
}
export default InteractiveMap;

InteractiveMap.propTypes = {

};
