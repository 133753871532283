import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tools from '../../services/tools.js';
import CardContent from './cardContentOne.js';
import Grid from '@material-ui/core/Grid';
import IMG1 from '../../assets/save/05.png';
import IMG2 from '../../assets/save/06.png';
import IMG3 from '../../assets/save/07.png';
import CardImg1 from '../../assets/cards/green_01.png';
import CardImg2 from '../../assets/cards/green_02.png';
import CardImg3 from '../../assets/cards/pink_03.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: '75%',
    marginLeft: "15%",
    marginTop: '1%',
    marginBottom: '3%',
    textAlign: 'left',
  },
  rootMin: {
    flexGrow: 1,
    paddingTop: 50,
  },
}));

export default function ArtCardListForCourses(props) {
  const classes = useStyles();

  return (
    <div style={{marginTop: '5em'}}>
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        <div style={props.displaySmartphoneVersion ? {display: 'flex', width: '100%', paddingLeft: "80px"} : {display: 'flex', flexDirection: 'row', width: '70%'}}>
          <h2 className='fauneBold' style={props.displaySmartphoneVersion ? {color: Tools.primaryPurple, marginTop: -30} : {color: Tools.primaryPurple}}>Œuvres</h2>
        </div>
        <div style={props.displaySmartphoneVersion ? {display: 'flex', flexDirection: 'column', width: '100%'} : {display: 'flex', flexDirection: 'row', width: '70%', justifyContent: "space-between"}}>
          {
            props.poiList.map((value, index) => (
              <CardContent
                index={index}
                category={props.poiList[index].category}
                id={props.poiList[index].id}
                img={props.poiList[index].image1}
                {...props}
                title={props.poiList[index].name}
                subtitle={props.poiList[index].city}
                cardImg={props.poiList[index].flagImage}
                titleColor={props.poiList[index].category === "Fresques" ? Tools.primaryPink : Tools.primaryGreen}
                description={props.poiList[index].description}
                imgAlt='' />
            ))
          }
        </div>
      </div>
    </div>
  );
}
