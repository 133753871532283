import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tools from '../../services/tools.js';
import '../components.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "auto",
    width: 350,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    marginBottom: "3em"
  },
  paperMin: {
    height: "auto",
    width: 300,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
  },
}));

 const CardContentTwo = (props) => {
   const classes = useStyles();

   return (
     props.displaySmartphoneVersion
     ?
     <div className='cardContent pointer' onClick={() => props.handleCourseCardClicked(props.index, props.id)}>
       <Paper className={classes.paperMin}>
         <img src={props.img} alt={props.imgAlt} style={{width: '100%', height: '40%'}}/>
         <div style={{width: '90%', marginLeft: 'auto', marginRight: 'auto', position: "relative"}}>
          <p className='cardTitleNew fauneBold' style={{color: Tools.primaryPurple, width: "12em"}}>{props.title}</p>
          <p className=' fauneItalic'>{props.difficulty}</p>
          <p className='italic'>{props.subtitle}</p>
          <p className='courseTime fauneItalic' style={{color: Tools.primaryPink, paddingBottom: "20px"}}>{props.time}</p>
         </div>
       </Paper>
     </div>
     :
      <div className='cardContent pointer' onClick={() => props.handleCourseCardClicked(props.index, props.id)}>
        <Paper className={classes.paper}>
          <img src={props.img} alt={props.imgAlt} style={{width: '350px', height: '235px'}}/>
          <div style={{width: '90%', height: '55%', marginLeft: 'auto', marginRight: 'auto', position: "relative", paddingBottom: '30px'}}>
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: '-1.5em',}}>
              <p className='cardTitleNew fauneBold' style={{color: Tools.primaryPurple, width: "10em", paddingLeft: '10px'}}>{props.title}</p>
              <p className='cardDifficylty fauneItalic' style={{padding: '10px'}}>{props.difficulty}</p>
            </div>
            <p className='italic' style={{paddingLeft: '10px', paddingRight: '10px', color: '#525252'}}>{props.subtitle}</p>
            <p className='courseTime fauneItalic' style={{color: Tools.primaryPink, paddingLeft: '10px', paddingRight: '10px', marginTop: '-5px'}}>{props.time}</p>
            <div className='textDotWrapper'>
              <p className='ralewayLight' style={{color: Tools.primaryPurple, paddingLeft: '10px', paddingRight: '10px', lineHeight: '20px', overflow: 'hidden'}}>{props.description}</p>
            </div>
          </div>
        </Paper>
      </div>
  );
}
export default CardContentTwo;

CardContentTwo.propTypes = {
  index: PropTypes.string,
  handleCourseCardClicked: PropTypes.func,
  img: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  difficulty: PropTypes.string,
  subtitle: PropTypes.string,
  time: PropTypes.string,
  description: PropTypes.string
};
