import React  from 'react';
import PropTypes from 'prop-types';
import {markerStyle} from './markerStyle.js';
import TourristOfficeImage from '../../../assets/vae/tourrisOfficeIcon.svg';

const Marker = (props) => {
  return (
    <div onClick={() => props.website !== '' ? window.open(props.website, '_blank') : alert("Cet office du tourisme n'a pas de site internet")}>
      <img className="pointer" src={TourristOfficeImage} alt='' />
    </div>
  );
}

export default Marker;

Marker.propTypes = {
  clickOnMarker: PropTypes.func,
  origin: PropTypes.object,
  destination: PropTypes.object,
  index: PropTypes.number
};
