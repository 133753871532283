import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tools from '../../services/tools.js';
import Location from '../../assets/cards/location.png';
import '../components.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 'auto',
    width: 350,
  },
  paperMin: {
    padding: 10,
  },
}));

 const CardContentOne = (props) => {
   const classes = useStyles();

   return (
     props.displaySmartphoneVersion
     ?
     <div className='cardContent pointer' style={{marginBottom: 50, paddingLeft: "40px", paddingRight: "40px"}}>
       <Paper className={classes.paperMin} onClick={() => props.displayPage('artPresentation', props.id)}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <img src={props.img} alt={props.imgAlt} style={{width: '40%', minWidth: "150px", maxWidth: "250px", height: 'auto'}}/>
          <div style={{display: "flex", flexDirection: "column", marginLeft: 10}}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <p className='cardTitle fauneItalic' style={{color: props.titleColor, fontSize: '0.9em', textAlign: "left"}}>{props.title}</p>
              <div style={{display: "flex", flexDirection: "row", marginTop: -10}}>
                <img src={Location} alt='location' style={{height: 15, marginRight: '0.5em'}}/>
                <p className='fauneItalic' style={{color: Tools.primaryPurple, fontSize: '0.8em', marginTop: -2}}>{props.subtitle}</p>
              </div>
              <div className='textDotWrapper' style={{height: 'auto'}}>
                <p className='ralewayLight' style={{color: props.descriptionColor, fontSize: "0.7em", marginTop: -1, textAlign: "left", overflow: 'hidden'}}>{props.description}</p>
              </div>
            </div>
          </div>
        </div>
       </Paper>
     </div>
     :
     <div className='cardContent pointer'>
      <Paper className={classes.paper} onClick={() => props.displayPage('artPresentation', props.id)}>
        <img src={props.img} alt={props.imgAlt} style={{width: 350, height: 235, borderTopLeftRadius: 5, borderTopRightRadius: 5}} />
        <div style={{display: 'flex', flexDirection: 'row', marginBottom: '1em'}}>
          <div style={{width: '75%', height: '5em', paddingLeft: '30px'}}>
            <p className='cardTitle fauneItalic' style={props.category === 'Fresques' ? {color: Tools.primaryPink, fontSize: '22px', display: 'flex', justifyContent: 'left'} : {color: Tools.primaryGreen, fontSize: '22px', display: 'flex', justifyContent: 'left'}}>{props.title}</p>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: -40}}>
              <img src={Location} alt='location' style={{height: 20, marginTop: '1.6em', marginRight: '0.5em'}}/>
              <p className='fauneItalic' style={{color: Tools.primaryPurple, fontSize: '1.2em'}}>{props.subtitle}</p>
            </div>
          </div>
          <div style={{width: '25%', height: '5em', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={props.cardImg} alt={props.imgAlt} style={{width: '80%', height: '50%', paddingRight: '30px', paddingTop: '10px'}}/>
          </div>
        </div>
        <div className='textDotWrapper' style={{height: 'auto', paddingBottom: '30px'}}>
          <p className='ralewayLight' style={{color: '#525252', fontSize: '17px', lineHeight: '24px', paddingLeft: '30px', paddingRight: '30px', textAlign: 'left', overflow: 'hidden'}}>{props.description}</p>
        </div>
      </Paper>
    </div>
  );
}
export default CardContentOne;

CardContentOne.propTypes = {
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  img: PropTypes.string,
  imgAlt: PropTypes.string,
  displayPage: PropTypes.func,
  titleColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cardImg: PropTypes.string,

};
