import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tools from '../../../services/tools.js';
import '../../components.css';

 const BottomBlock = (props) => {
  return (
    <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%'}}>
      <p style={{color: 'dimgrey'}} className='bottomText ralewaySemibold'>Ce n’est pas tout ! Le street art est à l’honneur</p>
      <p style={{marginBottom: '2em', color: 'dimgrey', marginTop: -15}} className='bottomText ralewaySemibold'>grâce à nos fresques murales !</p>
      <Button
        variant="contained"
        onClick={() => props.displayPage('muralPainting', 0)}
        style={{backgroundColor: Tools.primaryPink, color: 'white', marginBottom: '1%', height: 40, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32, marginBottom: '5em'}}
      >
      <span style={{}}>Voir les fresques</span>
      {props.isLoading ? <span class='smallLoader' style={{fontSize: '0.5em'}}/> : null}
      </Button>
    </div>
  );
}
export default BottomBlock;

BottomBlock.propTypes = {
  displayPage: PropTypes.func,
};
