import React from 'react';
import PropTypes from 'prop-types';
import CardList from '../../cards/homeCourseCardList.js';
import Button from '@material-ui/core/Button';
import Tools from '../../../services/tools.js';
import '../../components.css';

 const CoursesBlock = (props) => {
  return (
    <div>
      <div id='coursesBlockDiv' style={{height: 'auto', paddingTop: 50, paddingBottom: 50, backgroundColor: Tools.primaryGrey}}>
        <CardList {...props}/>
        <div style={{marginTop: '3%', width: 'auto', paddingLeft: '10px', paddingRight: '10px'}}>
          <Button
            variant="contained"
            onClick={() => props.displayPage('culturalCourses', 0)}
            style={{backgroundColor: Tools.primaryGreen, color: 'white', width: 'auto', marginBottom: '1%', height: 40, fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px'}}
          >
          Voir tout les parcours
          </Button>
        </div>
      </div>
    </div>
  );
}
export default CoursesBlock;

CoursesBlock.propTypes = {
  displayPage: PropTypes.func
};
