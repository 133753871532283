import React from 'react';
import AppBar from '../components/appBar/appBar.js';
import Footer from '../components/footer/footer.js';
import Carousel from '../components/carousel/carouselImplementation.js';
import ButtonBlock from '../components/blocks/home/buttonBlock.js';
import ArtBlock from '../components/blocks/home/artBlock.js';
import CoursesBlock from '../components/blocks/home/coursesBlock.js';
import * as Services from '../services/apiServices.js';
import { withRouter} from "react-router-dom";
import * as Tools from '../services/tools.js';
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import PageLoader from './loaderPage.css';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import './screens.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0,
      screenHeight: 0,
      isNewsletterLoaderVisible: false,
      slide: 1,
      displaySmartphoneVersion: false,
      lastCourses: [],
      isLoading: true,
      carouselTimeout: {}
    };
  }

  async componentDidMount() {
    document.title = "Itinéraires artistiques";
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
    this._handleCarouselTitle();

    const lastCourses = await Services.getLastCourses();
    console.log('lastCourses');
    console.log(lastCourses);
    this.setState({lastCourses: lastCourses});
    this.setState({isLoading: false});

    /*const test = await Services.test();
    console.log("test");
    console.log(test);*/
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
  }

  //used to change the carousel title on slide change
  _handleCarouselTitle = () => {
    if (this.state.slide === 1) {
      this.setState({slide: 2});
    }
    else {
      this.setState({slide: 1});
    }
    const timeout = setTimeout(this._handleCarouselTitle, 10000);
    this.setState({carouselTimeout: timeout});
  }

  //used to display new page on screen
  _displayPage = (page, id) => {
    const url = getScreenComponent(page, id);
    this.props.history.push(url);
  }

  //function called to open or close snackbar
  _handleSnackbarState = () => {
    const currentState = this.state.isSnackbarVisible;
    this.setState({isSnackbarVisible: !currentState});
  }

  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  _handleCarouselSlideChange = (currentSlide) => {
    clearTimeout(this.state.carouselTimeout);
    this._handleCarouselTitle();
    //alert('current slide : ' + currentSlide);
    /*if (this.state.slide === 1) {
      this.setState({slide: 2});
    }
    else {
      this.setState({slide: 1});
    }*/

  }

  _test = async() => {
    console.log('start test')
    const ret = await Services.test();
    console.log('stop test')
  }

  _handleLoaderState = () => {
    const currentState = this.state.isNewsletterLoaderVisible;
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  render() {
    return(
      this.state.isLoading ? <div style={{display: "flex", justifyContent: "center", marginTop: "50vh", transform: "translateY(-50%)"}}class="lds-circle"><div></div></div> :
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <Carousel {...this.state} location={this.props.location.pathname} displayPage={this._displayPage} slideChange={this._handleCarouselSlideChange}/>
          <ButtonBlock test={this._test} displayPage={this._displayPage} {...this.state}/>
          <ArtBlock displayPage={this._displayPage} {...this.state}/>
          {/*<CoursesBlock {...this.state} displayPage={this._displayPage}/>*/}
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (App);
