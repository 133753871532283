import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardContent from './muralPaintingCardContent.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    width: '75%',
    marginLeft: "15%",
    marginTop: '1%',
    marginBottom: '3%',
    textAlign: 'left',
  },
}));

export default function MuralPaintingCardList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.poiList.map((value, index) => (
        <div style={props.displaySmartphoneVersion ? {width: "100%"} : {width: "33%", minWidth: "360px"}}>
          <CardContent
            category={props.category}
            id={props.poiList[index].id}
            img={props.poiList[index].image1}
            title={props.poiList[index].name}
            subtitle={props.poiList[index].city}
            description={props.poiList[index].description}
            cardImg={props.poiList[index].flagImage}
            category={props.poiList[index].category}
            imgAlt=''
            {...props}
          />
        </div>
      ))}
    </div>
  );
}
