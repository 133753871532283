import React from 'react';
import Part1 from '../../../assets/about/part1.png';
import Part2 from '../../../assets/about/part2.png';
import Part3 from '../../../assets/about/part3.png';
import Part4 from '../../../assets/about/part4.png';
import Part5 from '../../../assets/about/part5.png';
import Part6 from '../../../assets/about/part6.png';
import Tools from '../../../services/tools.js';
import '../../components.css';

 const PartnersBlock = (props) => {
  return (
    props.displaySmartphoneVersion
    ?
    <div style={{marginTop: '1%', textAlign: 'Left', marginLeft: '15%', marginBottom: '5%', marginRight: '15%'}}>
      <h2 className='fauneBold' style={{color: Tools.primaryPurple, fontSize: '2em'}}>Nos partenaires</h2>
        <img src={Part1} alt='about part 1' style={{width : '99%'}}/>
      <div style={{display: 'flex', flexDirection: 'row'}}>


          <div style={{marginTop: '1%', textAlign: 'Left'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 15, marginBottom: 15}}>
              <img src={Part2} alt='about part 2' style={{marginRight: '0.4em'}}/>
              <img className='aboutImg' src={Part3} alt='about part 3' />
            </div>
            <img className='aboutImg' src={Part4} alt='about part 4'/>
          </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 15}}>
        <img className='aboutImg' src={Part5} alt='about part 5' style={{width: 180, height: 75}}/>
        <img className='aboutImg' src={Part6} alt='about part 6'  style={{width: 100, height: 75}}/>
      </div>
    </div>
    :
    <div style={{marginTop: '1%', textAlign: 'Left', marginLeft: '15%', marginBottom: '5%', marginRight: '15%'}}>
      <h2 className='fauneBold' style={{color: Tools.primaryPurple, fontSize: '2em'}}>Nos partenaires</h2>
      <img src={Part1} alt='about part 1' />
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <img src={Part2} alt='about part 2' style={{marginRight: '0.4em'}}/>
        <img className='aboutImg' src={Part3} alt='about part 3' />
        <img className='aboutImg' src={Part4} alt='about part 4' />
        <img className='aboutImg' src={Part5} alt='about part 5' />
        <img className='aboutImg' src={Part6} alt='about part 6' />
      </div>
    </div>
  );
}
export default PartnersBlock;
